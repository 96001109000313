// @ts-nocheck
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'react-onsenui';
import _ from 'lodash';
import { postProducts } from '../../../../actions/dairyCustomerProduct';
import { updateProduct, fetchProducts } from '../../../../actions/product';
import moment from 'moment';
import Select from 'react-select';
import Tooltip from 'react-power-tooltip';
import InnerNav from '../InnerNav';
import { copyObject, replaceAll } from '../../../library/util';
import SimpleValidator from '../../../common/SimpleValidator';
import withLanguage from '../../../language/LanguageController';
import MuiDatepicker from '../../../common/MuiDatepicker';
import analytics from '../../../library/firebase/analytics';
import { getCustomerLabel } from "../../../library/customer";
import CustomersSelect from '../../common/CustomersSelect';
import { DAIRY_CUSTOMER_CODE_DEBOUNCE } from '../../../../_constants';
import { DefaultLayout } from '../../common/Layout';
import Eventer from '../../../library/Eventer';
import { DefaultScrollbar } from '../../common/Scrollbar';
import { checkFeatureInSubscription } from '../../../common/FeatureFlag/FeatureFlag';
import SubscriptionLock from '../../../common/FeatureFlag/SubscriptionLock';

class ProductForm extends Component {
  constructor(props) {
    super(props);
    let customer = this.props.customers.length ? copyObject(this.props.customers[0]) : {};
    let selectedCustomer = {};
    if(this.props.customers.length) {
      selectedCustomer = {
        value: customer.dairy_customer_id,
        label: getCustomerLabel(customer),
        ...customer
      };
    }
    this.state = {
      minDate: moment().subtract(1, 'month').startOf('month'),
      maxDate: moment(),
      date: moment(),
      selectedCustomer: selectedCustomer,
      code: selectedCustomer ? selectedCustomer.code : "",
      selectedProduct: null,
      price: '',
      qHelp: false,
      isSaving: false
    };
    this.validator = new SimpleValidator();
    this.handleCodeChange = _.debounce(this.handleCodeChange.bind(this), DAIRY_CUSTOMER_CODE_DEBOUNCE);
  }

  componentDidMount () {
    analytics.setScreenName("CustomerProductForm");
    if(this.props.products.length <= 0) {
      this.props.fetchProducts(this.props.dairyId);
    }
  }

  inputChangeHandler = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;
    this.setState({
      [name]: value
    }, () => {
      if(name === "price" || name === "quantity"){
        this.setState({
          total: (this.state.price * this.state.quantity).toFixed(2)
        })
      }
    });
  }

  dateChangeHandler = (selectedDate) => {
    this.setState({
      date: selectedDate
    });
  }

  customerChangeHandler = customer => {
    this.setState({
      selectedCustomer: customer
    }, () => {
      document.getElementById("customer_code").value = customer.code;
    });
  }

  handleCodeChange(event) {
    const code = event.target.value;
    const customer = this.props.customers.find(customer => customer.code == code);
    if(customer) {
      this.customerChangeHandler(customer);
    } else {
      this.setState({
        selectedCustomer: {
          label: this.props.t("text_no_customer_for_code"),
          value: null
        }
      });
    }
  }

  productChangeHandler = product => {
    let price = product.price ? product.price : 0;
    
    this.setState({
      price: price,
      total: (price * this.state.quantity).toFixed(2),
      selectedProduct: product
    });
  }

  validateField = e => {
    if(this.validator.fieldValid(e.target.name)) {
      this.validator.hideMessageFor(e.target.name);
    } else {
      this.validator.showMessageFor(e.target.name);
    }
    this.forceUpdate();
  }

  formSubmitHandler = (e) => {
    e.preventDefault();
    // validate form before submitting to server 
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate(); // rerender to show messages for the first time
      return false;
    }
    analytics.logEvent("saleProduct");
    this.setState({
      isSaving: true
    });

    this.props.postProducts(this.props.dairyId, (isSuccess) => {
      if(isSuccess) {
        let product = _.find(this.props.products, {
          id: this.state.selectedProduct.value
        });
        let productIndex = (this.props.products).indexOf(product);

        product.quantity -= parseFloat(this.state.quantity);
        this.props.updateProduct(productIndex, product);
        this.setState({
          selectedProduct: null,
          price: '',
          quantity: '',
          total: '',
          isSaving: false
        }, () => {
          this.validator.clearAll();
          this.forceUpdate();
        });
        Eventer.emit("salesAdded");
      } else {
        this.setState({
          isSaving: false
        });
      }
    });
  }

  hideHelp = () => {
    if(this.state.qHelp) {
      this.setState({
        qHelp: false
      });
    }
  }

  showHelp = (e, type) => {
    e.stopPropagation();
    analytics.logEvent("showHelp");
    this.setState({
      [type + "Help"]: !this.state[type + "Help"],
    });
  }

  render() {
    const { isSaving } = this.state
    const { t, header, bottomGutter } = this.props;
    return (
      <DefaultLayout 
        title={t("text_add_product_sell")}
        onClick={this.hideHelp}
        toolbar={false}
        header={header}
        bottomGutter={bottomGutter}
        back>
        <DefaultScrollbar
          className="dairy-product-sales-form-scrollbar">
          <div className="t-card shadow-sm">
            <form id="productForm"
              onSubmit={this.formSubmitHandler}
              encType={"multipart/form-data"}>
              <div className="form-group">
                <label>{t("text_date")}</label>
                <MuiDatepicker
                  value={this.state.date}
                  minDate={this.state.minDate}
                  maxDate={this.state.maxDate}
                  onChange={this.dateChangeHandler}
                  name="date"
                />
                {this.validator.message('date', this.state.date, 'required')}
              </div>
              <div className="form-row">
                <div className="col-3">
                  <div className="form-group">
                    <label htmlFor="customer_code">{t("text_code")}</label>
                    <input
                      className="form-control"
                      type="number"
                      id="customer_code"
                      defaultValue={this.state.code}
                      onChange={e => { e.persist(); this.handleCodeChange(e)}} />
                  </div>  
                </div>
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="dairy_customer_id">{t("text_customer")}</label>
                    <CustomersSelect
                      name="dairy_customer_id"
                      id="dairy_customer_id"
                      customers={this.props.customers}
                      value={this.state.selectedCustomer}
                      onChange={this.customerChangeHandler}
                      placeholder={t("text_select_customer") + "..."}
                    />
                    {this.validator.message('customer', this.state.selectedCustomer.value, 'required')}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>{t("text_product")}</label>
                <Select
                    noOptionsMessage={() => {
                      return t("text_no_options");
                    }}
                    options={this.props.products.map(product => {
                      return { 
                        value: product.id, 
                        label: product.name + "-" + product.code,
                        price: product.price,
                        quantity: product.quantity
                      };
                    })}
                    placeholder={t("text_select_product") + "..."}
                    name="product_id"
                    value={this.state.selectedProduct}
                    onChange={this.productChangeHandler}/>
                {this.validator.message('product', this.state.selectedProduct, 'required')}
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>{t("text_price")}</label>
                    <input
                      type="number"
                      className="form-control"
                      aria-describedby="Price"
                      name="price"
                      step="0.01"
                      min="0"
                      onChange={this.inputChangeHandler}
                      onBlur={this.validateField}
                      value={this.state.price} />
                    {this.validator.message('price', this.state.price, 'required|numeric|min:0,num')}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>
                      {t("text_quantity")} &nbsp;
                      {this.state.selectedProduct ? (
                        <Fragment>
                          <Icon icon="md-help" onClick={e => this.showHelp(e, 'q')}/>
                          <Tooltip 
                            show={this.state.qHelp}
                            position="top center">
                            <span>
                              {this.state.selectedProduct.quantity > 0 ? 
                                replaceAll(t("text_product_quantity_help"), '{number}', this.state.selectedProduct.quantity) :
                                t("text_product_out_stock")}
                            </span>
                          </Tooltip>
                        </Fragment>
                      ) : null}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      aria-describedby="Quantity"
                      name="quantity"
                      readOnly={this.state.selectedProduct && this.state.selectedProduct.quantity < 1}
                      max={this.state.selectedProduct ? this.state.selectedProduct.quantity : 0}
                      onChange={this.inputChangeHandler}
                      onBlur={this.validateField}
                      min="1"
                      step="0.01"
                      value={this.state.quantity} />
                    {this.validator.message('quantity', this.state.quantity, `required|numeric|min:1,num|max:${this.state.selectedProduct ? this.state.selectedProduct.quantity : 0},num`)}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>{t("text_amount")}</label>
                <input
                  type="number"
                  className="form-control"
                  aria-describedby="Amount"
                  name="total"
                  readOnly
                  value={this.state.total || ""} />
                {this.validator.message('amount', this.state.total, 'required|numeric')}
              </div>
              <div className="pb-0 border-top pt-2">
                <button
                  type="submit"
                  className="btn btn-success btn-block"
                  disabled={isSaving}>
                  {t("text_save")}
                </button>
              </div>
            </form>
          </div>
        </DefaultScrollbar>
        <InnerNav
          menu="product"
          page="addSales"
          navigator={this.props.navigator}  />
        {!checkFeatureInSubscription("product_management", false) && <SubscriptionLock/>}
      </DefaultLayout>
    );
  }
}

ProductForm.displayName = "ProductForm";

ProductForm.defaultProps = {
  header: true,
  bottomGutter: true
};

const mapStateToProps = state => ({
  customers: state.dairy_customers.customers,
  products: state.productReducer.products || [],
  dairyId: state.dairy.dairy.id
});

const mapDispatchToProps = {
  postProducts,
  updateProduct,
  fetchProducts
};

export default connect(mapStateToProps, mapDispatchToProps)(withLanguage(ProductForm));