// @ts-nocheck
import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ons from "onsenui";
import React, { Component } from 'react';
import {
    Splitter,
    SplitterContent
} from 'react-onsenui';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import appJson from "../../package.json";
import { fetchRateLists } from "../actions/dairyRateList";
import { fetchAppConfiguration, toggleSideBar, toggleSideBarClose } from "../actions/index";
import { fetchSubscription } from "../actions/subscriptions";
import { setLogin } from "../actions/user";
import config from '../config';
import InitialLoader from './common/InitialLoader';
import MainNavigationDrawer from "./common/Navigation/MainNavigationDrawer";
import Portals from "./common/Portals";
import Routes from "./common/Routes";
import withLanguage from './language/LanguageController';
import { checkLogin } from './library/auth';
import { trackUser } from './library/errorTracking';
import initFCMMessaging, { generateFCMToken } from './library/firebase/FCMMessaging/handleFCMMessaging';
import analytics from "./library/firebase/analytics";
import { confirmPop } from './library/util';
import User from './models/User';

const initSentry = () => {
    Sentry.init({
        dsn: "https://f903df0e5a584177808b6a88af0116bb@o498949.ingest.sentry.io/5576973",
        environment: config.environment,
        autoSessionTracking: true,
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        release: `liter@${appJson.version}`,
        tracesSampleRate: 0.1,
    });
}

class App extends Component {

    constructor() {
        super();
        this.state = {
            isOpen: false,
            isLogin: false,
            isLoading: true,
            user: null
        };
        this.checkUserAuthentication = this.checkUserAuthentication.bind(this);
        this.user = new User();
        this.exitPopup = false;
    }

    componentDidMount() {
        initSentry();
        ons.setDefaultDeviceBackButtonListener(this.onBackKeyDown);
        this.checkUserAuthentication();
        if (typeof window.SMSReceive !== "undefined") {
            window.SMSReceive.requestPermission();
        }
        initFCMMessaging();
        generateFCMToken();
    }

    async checkUserAuthentication() {
        const isLogin = await checkLogin();
        if (isLogin) {
            this.props.setLogin(true);
            this.setState({
                isLoading: false
            });
        } else {
            this.props.setLogin(false);
            this.setState({
                isLoading: false
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { user, isLogin } = this.props;
        if (prevProps.isLogin != isLogin && isLogin) {
            
            analytics.setUserId(user.customer_id);
            analytics.setUserProperty("userName", user.firstname + " " + user.lastname);
            analytics.setUserProperty("userGroup", user.customer_group_id);
            trackUser(user);
            generateFCMToken();
            amplitude.init(config.amplitude_key);
        }
    }

    onBackKeyDown = (event) => {
        try {
            const { history } = this.props;
            if(!this.checkBodyHasSwalClass()) {
                history.index !== 0 ? this.props.history.goBack() : this.exitApp();
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    checkBodyHasSwalClass = () => {
        return document.getElementsByTagName("body")[0].classList.contains("swal2-shown");
    }

    exitApp = async () => {
        if(this.exitPopup || typeof cordova === "undefined") {
            return false;
        }
        this.exitPopup = true;
        const clickedButton = await confirmPop(this.props.t("text_confirm_close_app"));
        if (clickedButton === 1) {
            navigator.app.exitApp();
        }
        this.exitPopup = false;
    }

    render() {
        return (
            <Splitter>
                <MainNavigationDrawer/>
                <SplitterContent className="overflow-auto">
                    {this.state.isLoading ? <InitialLoader /> : <Routes/>}
                    <Portals
                        isLogin={this.props.isLogin}
                    />
                </SplitterContent>
            </Splitter>
        );
    }
}

const mapStateToProps = state => {
    const {
        defaultReducer: {
            language,
            isSidebarOpen
        },
        userReducer: {
            user,
            is_login
        },
        dairy: {
            dairy
        }
    } = state;
    return {
        language,
        user,
        isLogin: is_login,
        isSidebarOpen,
        dairyId: dairy?.id
    };
}

const mapDispatchToProps = {
    fetchAppConfiguration,
    fetchSubscription,
    fetchRateLists,
    setLogin,
    toggleSideBar,
    toggleSideBarClose
};

export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(withLanguage(App))
);