// @ts-nocheck
import React, { Component } from "react";
import { Ripple } from 'react-onsenui';
import _ from 'lodash';
import moment from "moment";
import { connect } from "react-redux";
import { toast, loader, copyObject } from "../../../library/util";
import InnerNav from "../InnerNav";
import withLanguage from "../../../language/LanguageController";
import { errorHandler } from "../../../library/response";
import SimpleValidator from "../../../common/SimpleValidator";
import analytics from "../../../library/firebase/analytics";
import request from "../../../library/request";
import CustomersSelect from "../../common/CustomersSelect";
import { DAIRY_CUSTOMER_CODE_DEBOUNCE } from '../../../../_constants';
import config from "../../../../config";
import { DefaultLayout } from "../../common/Layout";
import Eventer from "src/components/library/Eventer";
import { checkFeatureInSubscription } from "../../../common/FeatureFlag/FeatureFlag";

class SanctionLoan extends Component {
    constructor(props) {
        super(props);
        this.loanSanctionHandler = this.loanSanctionHandler.bind(this);
        const today = moment().format(config.date_format);
        const customers = copyObject(props.customers);
        this.state = {
            "maxDate": today,
            "date": today,
            "customers": customers.filter(customer => customer.type === 'seller'),
            "dairy_customer_id": "",
            selectedCustomer: null,
            isFeatureSubscribed: checkFeatureInSubscription("loan", false)
        }
        this.validator = new SimpleValidator();
        this.handleCodeChange = _.debounce(this.handleCodeChange.bind(this), DAIRY_CUSTOMER_CODE_DEBOUNCE);
    }

    componentDidMount() {
        analytics.setScreenName("SanctionLoan");
    }

    async loanSanctionHandler(e) {
        e.preventDefault();
        if(!checkFeatureInSubscription("loan")) {
            return false;
        }
        try {
            // validate form before submitting to server 
            if (!this.validator.allValid()) {
                this.validator.showMessages();
                this.forceUpdate(); // rerender to show messages for the first time
                return false;
            }
            analytics.logEvent("sanctionLoan");
            loader.show(this.props.t("text_loading"));
            this.setState({
                loan_sanction_btn: true
            });
            const { status, message, error_code } = await request.post('/dairies/' + this.props.dairyId + `/accounts/loan/sanctioned`, {
                withAuth: true,
                body: new FormData(document.getElementById("sanctionLoanForm")),
                version: "v2"
            });
            if (status) {
                toast(message);
                document.getElementById("sanctionLoanForm").reset();
                this.setState({
                    amount: ""
                }, () => {
                    document.getElementById("customer_code").value = this.state.selectedCustomer.code;
                    this.validator.hideMessages();
                });
                Eventer.emit("loanSanctioned", {
                    dairyCustomerId: this.state.dairy_customer_id
                });
            } else if (error_code != 501) {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
            this.setState({
                loan_sanction_btn: false
            });
        }
    }

    handleInput = (e) => {
        let target = e.target;
        let name = target.name;
        let value = target.value;

        this.setState({
            [name]: value
        });
    }

    customerChangeHandler = customer => {
        this.setState({
            dairy_customer_id: customer.dairy_customer_id,
            selectedCustomer: customer
        }, () => {
            document.getElementById("customer_code").value = customer.code;
        });
    }

    handleCodeChange(event) {
        const code = event.target.value;
        const customer = this.state.customers.find(customer => customer.code == code);
        if (customer) {
            this.customerChangeHandler(customer);
        } else {
            this.setState({
                dairy_customer_id: null,
                selectedCustomer: {
                    label: this.props.t("text_no_customer_for_code"),
                    value: null
                }
            });
        }
    }

    render() {
        const { t, header, bottomGutter, innerNav } = this.props;
        const { customers, selectedCustomer } = this.state;
        return (
            <DefaultLayout
                bottomGutter={bottomGutter}
                header={header}
                back
                title={t("text_sanction_loan")}
                toolbar={false}>
                <div className="t-card shadow-sm">
                    <h2 className="h4">{t("text_sanction_loan")}</h2>
                    <form method="post" id="sanctionLoanForm" onSubmit={this.loanSanctionHandler}>
                        <div className="form-group">
                            <label htmlFor="load_date">{t("text_date")}</label>
                            <input
                                id="load_date"
                                className="form-control"
                                type="text"
                                readOnly
                                defaultValue={moment().format(config.display_date_format)} />
                        </div>
                        <div className="form-row">
                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="customer_code">{t("text_code")}</label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        id="customer_code"
                                        defaultValue={this.state.code}
                                        onChange={e => {
                                            e.persist();
                                            this.handleCodeChange(e)
                                        }} />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="loan_dairy_customer_id">{t("text_customer")}</label>
                                    <CustomersSelect
                                        placeholder={t("text_select_customer") + "..."}
                                        value={selectedCustomer}
                                        onChange={this.customerChangeHandler}
                                        customers={customers}
                                        name="dairy_customer_id"
                                        id="loan_dairy_customer_id" />
                                    {this.validator.message("select_customer", this.state.dairy_customer_id, "required")}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="loan_amount">{t("text_loan_amount")}</label>
                            <input
                                name="amount"
                                type="number"
                                className="form-control"
                                onChange={this.handleInput}
                                id="loan_amount" />
                            {this.validator.message("loan_amount", this.state.amount, "required|min:1,num")}
                        </div>
                        <div className="form-group">
                            <label htmlFor="loan_remark">{t("text_remark")}</label>
                            <textarea
                                name="remark"
                                className="form-control"
                                id="loan_remark" />
                        </div>
                        <div className="form-row">
                            <div className="col-12">
                                <button
                                    className="btn btn-success btn-block"
                                    onClick={this.loanSanctionHandler}>
                                    <Ripple />
                                    {t("text_sanction")}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                {innerNav ? <InnerNav menu="loan" page="sanctionLoan"/> : null}
            </DefaultLayout>
        )
    }
}

SanctionLoan.displayName = "SanctionLoan";

SanctionLoan.defaultProps = {
    header: true,
    bottomGutter: true,
    innerNav: true
};

const mapStateToProps = state => {
    return {
        customers: state.dairy_customers.customers,
        dairyId: state.dairy.dairy.id
    }
}

export default connect(mapStateToProps)(withLanguage(SanctionLoan));