
import React, { useState } from 'react';
import { PrimaryButton } from 'src/components/common/buttons/PrimaryButton';
import ModalDialog from 'src/components/common/ModalDialog';
import { PrimaryTextField } from 'src/components/core/TextField';
import { t } from "src/components/language/LanguageController";
import { useStyles } from "./style";

export default function RateListNameChangeModal({ open, handleClose, rateListName, setRateListName, handleRenameSubmit }) {
  const classes = useStyles();
  const [tempRateListName, setTempRateListName] = useState(rateListName);

  const handleModalClose = () => {
    setRateListName(tempRateListName);
    handleClose();
  };

  return (
    <ModalDialog
      isOpen={open}
      title={t("text_rename_rate_list")}
      content={
        <form onSubmit={handleRenameSubmit}>
          <PrimaryTextField
            autoFocus={true}
            className={classes.input}
            required
            variant="outlined"
            size='small'
            name="name"
            value={rateListName}
            onChange={(event) => setRateListName(event.target.value)}
          />
          {!rateListName && (
            <div className={classes.errorMessage}>
              {(t("error_the_name_field_is_required."))}
            </div>)}
          <div className={classes.button}>
            <PrimaryButton disabled={!rateListName} type="submit" >{(t("text_save"))}</PrimaryButton>
          </div>
        </form>
      }
      dividers={false}
      onClose={handleModalClose}
    />
  );
}
