// @ts-nocheck
import React from 'react';
import moment from "moment";
import _ from 'lodash';
import * as Sentry from "@sentry/react";
import { loader, toast, socialSharing } from "../../../library/util";
import { customerBillTemplate } from '../../../library/smsTemplates';
import { connectLastPrinter, isConnected } from '../../../library/bluetoothPrinter';
import { customerBillReceipt } from '../../../templates/printerTemplates';
import { errorHandler } from '../../../library/response';
import SimpleValidator from '../../../common/SimpleValidator';
import { getBillingStartEndDate } from '../../../library/dateUtils';
import { checkFeatureInSubscription } from '../../../common/FeatureFlag/FeatureFlag';
import analytics from '../../../library/firebase/analytics';
import request from '../../../library/request';
import { getCustomerLabel } from '../../../library/customer';
import { DAIRY_CUSTOMER_CODE_DEBOUNCE } from '../../../../_constants';
import Eventer from '../../../library/Eventer';
import smsApp from '../../../library/smsApp';
import config from '../../../../config';

class CustomerAccountController extends React.Component {

    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.getAdvancePayment = this.getAdvancePayment.bind(this);
        this.getMilkTotal = this.getMilkTotal.bind(this);
        this.milkBillPayment = this.milkBillPayment.bind(this);
        this.milkBillReceive = this.milkBillReceive.bind(this);
        this.printMilkBill = this.printMilkBill.bind(this);
        let customer = props.customers[0] || {};
        const dairyCustomerId = props?.match?.params?.dairyCustomerId;
        if (dairyCustomerId) {
            customer = this.props.customers.find(customer => customer.dairy_customer_id == dairyCustomerId) || {};
        }
        let selectedCustomer = null;
        if (props.customers.length) {
            selectedCustomer = {
                ...customer,
                label: getCustomerLabel(customer),
                value: customer.dairy_customer_id
            }
        }
        this.state = {
            isBSetting: false,
            milk_payment_btn: false,
            milk_payment_modal: false,
            loan_payment_btn: false,
            loan_payment_modal: false,
            loan_sanction_modal: false,
            loan_sanction_btn: false,
            product_modal: false,
            advance_payment_modal: false,
            milk_total_modal: false,
            selectedCustomer: selectedCustomer,
            code: selectedCustomer ? selectedCustomer.code : "",
            filter_modal: false,
            records: [],
            total: {
                "milk": "0.00",
                "amount": "0.00",
                "purchase": "0.00",
                "payable": "0.00"
            },
            selectedDate: {
                year: this.cYear,
                month: this.cMonth
            },
            loan_detail: {},
            customer_type: customer.type,
            products: [],
            milk_detail: {},
            isBrowser: false,
            isFeatureSubscribed: checkFeatureInSubscription("customer_billing", false),
            ...getBillingStartEndDate(props.billingCycle, moment())
        };

        this.handleCodeChange = _.debounce(this.handleCodeChange.bind(this), DAIRY_CUSTOMER_CODE_DEBOUNCE);
    }

    componentDidMount() {
        analytics.setScreenName("CustomerAccount");
        this.getData();
        connectLastPrinter().catch(console.log);
        if(typeof this.props.onCustomerSelect === "function") {
            if(this.state.selectedCustomer?.value) {
                this.props.onCustomerSelect(this.state.selectedCustomer.value);
            }
            if(this.state.startDate && this.state.endDate) {
                this.props.onDateChange({
                    startDate: this.state.startDate,
                    endDate: this.state.endDate
                });
            }
        }
    }

    async getData() {
        if (!this.state.selectedCustomer || !this.state.isFeatureSubscribed) {
            return false;
        }
        try {
            loader.show(this.props.t("text_loading"));

            let { startDate, endDate } = this.state;
            startDate = startDate.format('DD-MM-YYYY');
            endDate = endDate.format('DD-MM-YYYY');

            let filterStr = encodeURI(`start_date=${startDate}&end_date=${endDate}`);
            const { status, data, message, error_code } = await request.get('/dairies/' + this.props.dairyId + `/bills/${this.state.selectedCustomer.value}?${filterStr}`, {
                withAuth: true,
                version: "v3"
            });
            if (status) {
                let payableAmount = parseFloat(data.payable_amount || 0),
                    paidAmount = parseFloat(data.paid_amount || 0),
                    remainingAmount = parseFloat(data.remaining || 0),
                    amountToPay = 0,
                    amountToReceive = 0;

                if (remainingAmount < 0 && payableAmount > 0 && payableAmount !== paidAmount) {
                    amountToPay = payableAmount - paidAmount;
                } else if (remainingAmount > 0) {
                    amountToPay = remainingAmount;
                }
                const { customer_type: customerType } = this.state;
                if (remainingAmount < 0 || customerType === "buyer") {
                    amountToReceive = remainingAmount;
                }
                this.setState({
                    ...data,
                    amount_to_pay: amountToPay,
                    amount_to_receive: amountToReceive
                });
                await Promise.all([
                    this.getProducts(),
                    this.getMilkTotal(),
                    this.getAdvancePayment()
                ]);
            } else if (error_code != 501) {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    async getProducts() {
        if (!this.state.isFeatureSubscribed) {
            return false;
        }
        try {
            let { startDate, endDate, selectedCustomer: { value: dairyCustomerId } } = this.state;
            startDate = startDate.format('YYYY-MM-DD');
            endDate = endDate.format('YYYY-MM-DD');
            const filterStr = `view=customer&date=${startDate}:${endDate}&dairy_customer_id=${dairyCustomerId}`;
            const { status, data } = await request.get('/dairies/' + this.props.dairyId + `/customer/products?${filterStr}`, {
                withAuth: true,
                version: "v2"
            });
            if (status) {
                let products = [];
                let productTotal = 0;
                if (data && data.result && data.result[0] && data.result[0] && data.result[0].childs) {
                    products = data.result[0].childs;
                    productTotal = data.total.amount;
                }
                this.setState({
                    products: products,
                    productTotal: productTotal
                });
            } else {
                this.setState({
                    products: [],
                    productTotal: 0
                });
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    async getAdvancePayment() {
        if (!this.state.isFeatureSubscribed) {
            return false;
        }
        try {
            let { startDate, endDate, selectedCustomer: { value: dairyCustomerId } } = this.state;
            startDate = startDate.format('YYYY-MM-DD');
            endDate = endDate.format('YYYY-MM-DD');
            const filterStr = encodeURI(`start_date=${startDate}&end_date=${endDate}`);
            const { status, data } = await request.get('/dairies/' + this.props.dairyId + `/accounts/advancePayment/${dairyCustomerId}?${filterStr}`, {
                withAuth: true,
                version: "v3"
            });
            if (status) {
                if (data) {
                    this.setState({
                        advance: data
                    });
                } else {
                    this.setState({
                        advance: []
                    });
                }
            } else {
                this.setState({
                    advance: []
                });
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    async getMilkTotal() {
        if (!this.state.isFeatureSubscribed) {
            return false;
        }
        try {
            let { startDate, endDate, selectedCustomer: { value: dairyCustomerId } } = this.state;
            let filterStr = `?date=${startDate.format('YYYY-MM-DD')}:${endDate.format('YYYY-MM-DD')}`;
            const { status, data } = await request.get('/dairies/' + this.props.dairyId + '/milk/' + dairyCustomerId + '/total' + filterStr, {
                withAuth: true,
                version: "v3"
            });
            if (status === true) {
                const { total, cow_total, buffalo_total } = data
                this.setState({
                    milk_detail: {
                        total: total,
                        cow_total: cow_total,
                        buffalo_total: buffalo_total
                    }
                });
            } else {
                this.setState({
                    milk_detail: {}
                });
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    onDateChange = ({ startDate, endDate }) => {
        this.setState({
            startDate,
            endDate
        }, () => {
            this.getData();
            if(typeof this.props.onDateChange === "function") {
                this.props.onDateChange({
                    startDate: startDate,
                    endDate: endDate
                });
            }
        });
    }

    customerChangeHandler = customer => {
        if(!checkFeatureInSubscription("customer_billing")) {
            return false;
        }
        this.setState({
            selectedCustomer: customer,
            customer_type: customer.type
        }, () => {
            document.getElementById("customer_code").value = customer.code;
            this.getData();
            if(typeof this.props.onCustomerSelect === "function") {
                this.props.onCustomerSelect(customer.value);
            }
        })
    }

    handleCodeChange(event) {
        const code = event.target.value;
        const customer = this.props.customers.find(customer => customer.code == code);
        if(customer) {
          this.customerChangeHandler(customer);
        } else {
          this.setState({
            selectedCustomer: {
              label: this.props.t("text_no_customer_for_code"),
              value: null
            }
          });
        }
    }

    toggleMilkPayModal = (e) => {
        e.preventDefault();
        if (!this.state.milk_payment_modal) {
            this.milkPaymentValidator = new SimpleValidator();
        }
        this.setState({
            milk_payment_modal: !this.state.milk_payment_modal
        });
    }

    async milkBillPayment(e) {
        e.preventDefault();
        try {
            // validate form before submitting to server 
            if (!this.milkPaymentValidator.allValid()) {
                this.milkPaymentValidator.showMessages();
                this.forceUpdate(); // rerender to show messages for the first time
                return false;
            }
            analytics.logEvent("milkBillPayment");
            loader.show(this.props.t("text_loading"));
            this.setState({
                milk_payment_btn: true
            });

            const { endDate, selectedCustomer: { dairy_customer_id } } = this.state;
            const formData = new FormData(document.getElementById("milk_bill_payment_form"));
            formData.append('bill_date', endDate.format("YYYY-MM-DD"));

            const { status, message, error_code } = await request.post('/dairies/' + this.props.dairyId + `/bills/pay/${dairy_customer_id}`, {
                withAuth: true,
                body: formData,
                version: "v3"
            });

            if (status) {
                toast(message);
                this.setState({
                    milk_payment_modal: false
                }, () => {
                    this.getData();
                    Eventer.emit("customerBillPaid");
                });
            } else if (error_code != 501) {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
            this.setState({
                milk_payment_btn: false
            });
        }
    }

    toggleMilkReceiveModal = (e) => {
        e.preventDefault();
        if (!this.state.milk_receive_modal) {
            this.milkReceiveValidator = new SimpleValidator();
        }
        this.setState({
            milk_receive_modal: !this.state.milk_receive_modal
        });
    }

    async milkBillReceive(e) {
        e.preventDefault();
        try {
            // validate form before submitting to server 
            if (!this.milkReceiveValidator.allValid()) {
                this.milkReceiveValidator.showMessages();
                this.forceUpdate(); // rerender to show messages for the first time
                return false;
            }
            analytics.logEvent("receiveMilkBill");
            loader.show(this.props.t("text_loading"));
            this.setState({
                milk_receive_btn: true
            });

            let { startDate, endDate, selectedCustomer: { dairy_customer_id } } = this.state;
            let billDate = endDate.format("YYYY-MM-DD");
            if (moment().isBetween(startDate, endDate)) {
                if (moment().isBetween(moment().format("YYYY-MM-01"), moment().format("YYYY-MM-10"))) {
                    billDate = moment().format("YYYY-MM-10");
                } else if (moment().isBetween(moment().format("YYYY-MM-11"), moment().format("YYYY-MM-20"))) {
                    billDate = moment().format("YYYY-MM-10");
                } else if (moment().isBetween(moment().format("YYYY-MM-21"), moment().format("YYYY-MM-20"))) {
                    billDate = (moment().endOf("month"))("YYYY-MM-10");
                }
            }

            const formData = new FormData(document.getElementById("milk_bill_receive_form"));
            formData.append('bill_date', billDate);
            const { status, message, error_code } = await request.post('/dairies/' + this.props.dairyId + `/bills/receive/${dairy_customer_id}`, {
                withAuth: true,
                body: formData,
                version: "v3"
            });
            if (status) {
                toast(message);
                this.setState({
                    milk_receive_modal: false
                }, () => {
                    this.getData();
                    Eventer.emit("customerBillReceived");
                });
            } else if (error_code != 501) {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
            this.setState({
                milk_receive_btn: false
            });
        }
    }

    toggleProductModal = () => {
        if(!this.state.isBrowser) {
            this.setState({
                product_modal: !this.state.product_modal
            });
        }
    }

    toggleAdvancePaymentModal = () => {
        if(!this.state.isBrowser) {
            this.setState({
                advance_payment_modal: !this.state.advance_payment_modal
            });
        }
    }

    toggleMilkTotalModal = () => {
        if(!this.state.isBrowser) {
            this.setState({
                milk_total_modal: !this.state.milk_total_modal
            });
        }
    }

    shareMilkBill = e => {
        e.preventDefault();
        analytics.logEvent("shareMilkBill");
        const { billingCycle } = this.props;
        const { selectedCustomer } = this.state;
        const message = customerBillTemplate(selectedCustomer, {...this.state, billingCycle});
        socialSharing(message, "");
    }

    sendSmsMilkBill = e => {
        e.preventDefault();
        analytics.logEvent("sendMilkBill");
        const { billingCycle } = this.props;
        const { selectedCustomer } = this.state;
        const message = customerBillTemplate(selectedCustomer, {...this.state, billingCycle});
        smsApp.sendTextSms(selectedCustomer?.c_mobile, message);
    }

    getCattleMilkStatement = async (dairyCustomerId, dairyId, startDate, endDate, cattle) => {
        let filterStr = `?milk_type=${cattle}&date=${startDate.format(config.date_format)}:${endDate.format(config.date_format)}`;
            filterStr += "&sort_column=date&sort_order=asc";
        const { data } = await request.get('/dairies/' + dairyId + '/customer_daily_milk_summary/' + dairyCustomerId + filterStr, {
            withAuth: true,
            version: "v2"
        });
        return data.result;
    }

    async printMilkBill(withStatement) {
        try {
            console.log("withStatement", withStatement);
            analytics.logEvent("printMilkBill");
            if (checkFeatureInSubscription("print")) {
                const connected = await isConnected();
                if (connected) {
                    loader.show(this.props.t("text_printing"));
                    const {
                        selectedCustomer: { name, dairy_customer_id, type: customerType }, startDate, endDate
                    } = this.state;
                    const { dairyName, billingCycle, dairyId } = this.props;
                    let cowMilkRecords = [];
                    let buffaloMilkRecords = [];
                    if(withStatement) {
                        cowMilkRecords = await this.getCattleMilkStatement(dairy_customer_id, dairyId, startDate, endDate, "cow");
                        buffaloMilkRecords = await this.getCattleMilkStatement(dairy_customer_id, dairyId, startDate, endDate, "buffalo");
                    }
                    await customerBillReceipt(dairyName, name, dairy_customer_id, customerType, billingCycle, this.state, cowMilkRecords, buffaloMilkRecords);
                } else {
                    this.setState({
                        isBSetting: true
                    });
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            loader.hide();
        }
    }

    togglePrinterModel = () => {
        this.setState({
            isBSetting: !this.state.isBSetting
        });
    }

    onDeviceChange = deviceTypes => {
        this.setState({
            ...deviceTypes
        });
    }
}

export default CustomerAccountController;
