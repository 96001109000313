import { Button, Menu, MenuItem, Tab } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteRateList, updateRateList, updateRateListName } from "src/actions/dairyRateList";
import { useLanguage } from "src/components/language/LanguageController";
import { errorHandler } from "src/components/library/response";
import { confirmPop, notification } from "src/components/library/util";
import { ABILITY_DAIRY_RATE_LIST, PERMISSION_DELETE, PERMISSION_UPDATE } from "../../../../../_constants/permissions";
import { usePermission } from "../../../../common/AccessPermission";
import { getRateListName } from "../../../../helpers/rateListHelper";
import RateListNameChangeModal from './RateListNameChangeModal';

const RateListTab = ({ rate_list_id, name, selected, active, onTabChange, index, ...restProps }) => {
    const { t } = useLanguage();
    const dispatch = useDispatch();
    const [rateListName, setRateListName] = useState(name);
    const { hasAccess } = usePermission();
    const hasUpdateAccess = hasAccess(ABILITY_DAIRY_RATE_LIST, PERMISSION_UPDATE);
    const hasDeleteAccess = hasAccess(ABILITY_DAIRY_RATE_LIST, PERMISSION_DELETE);
    const { 
        dairyRateList: {
            rateLists
        },
        dairy: {
            dairy
        }
    } = useSelector(state => state);

    const isNonDeletable = rateListName.toLowerCase().includes("default");

    const handleRenameSubmit = async (event) => {
        event.preventDefault();
        handleCloseModal();
    
        if (isNonDeletable) {
            notification("text_warning", t("text_cannot_rename_default_rate_list_warning"));
            return;
        }
    
        if (rateListName !== name) {
            try {
                await updateRateList(dispatch, dairy.id, rate_list_id, JSON.stringify({
                    name: rateListName,
                    rate_list_type_id: 1,
                    rate_list_id: rate_list_id,
                }));
    
                dispatch(updateRateListName(rate_list_id, rateListName));
    
                if (restProps.onRename) {
                    restProps.onRename(rate_list_id, rateListName);
                }
            } catch (error) {
                errorHandler(error);
            }
        }
    };
    

    const handleDelete = async () => {
        try {
            setAnchorEl(null);

            if (isNonDeletable) {
                notification("text_warning", t("text_cannot_delete_default_rate_list_warning"));
                return;
            }

            if(rateLists.length > 1) {
                const isConfirm = await confirmPop(t("text_delete_confirm"));
                if (isConfirm === 1) {
                    deleteRateList(dispatch, dairy.id, rate_list_id);
                }
            } else {
                notification("text_warning", t("text_delete_last_rate_list_warning"));
            }
        } catch (error) {
            errorHandler(error);
        }
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openModal, setOpenModal] = React.useState(false);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenModal = () => {
        setAnchorEl(null);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <>
            <Tab
                className="rate-list-tab"
                label={
                    <>
                        <span className="nav-link px-2 py-1 text-nowrap mw-100 overflow-hidden">
                            {getRateListName(rateListName)}{" "}
                        </span>
                        {!isNonDeletable && (hasUpdateAccess || hasDeleteAccess) && (
                            <>
                    <Button className="dropdown-toggle min-w-auto p-3 " aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                        <span className="sr-only">{t("text_open_menu")}</span>
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted={false}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {hasUpdateAccess && <MenuItem onClick={handleOpenModal}>
                            <i className="fa fa-edit text-green mr-2"/>
                            {t("text_rename")}
                        </MenuItem>}
                        {hasDeleteAccess && <MenuItem onClick={handleDelete}>
                            <i className="fa fa-trash text-danger mr-2"/>
                            {t("text_delete")}
                        </MenuItem>}
                    </Menu>
                </>)}
                </>
            }
            {...restProps}/>
            <RateListNameChangeModal
                open={openModal} 
                handleClose={handleCloseModal} 
                rateListName={rateListName} 
                setRateListName={setRateListName} 
                handleRenameSubmit={handleRenameSubmit} 
            />
        </>
    )
};

export default RateListTab;
