import React from 'react'
import { Container, createStyles, makeStyles } from "@material-ui/core";
import LoopIcon from "@material-ui/icons/Loop";

export const useStyles = makeStyles(() =>
createStyles({
  rotateIcon: {
    animation: "$spin 2s linear infinite",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(360deg)"
    },
    "100%": {
      transform: "rotate(0deg)"
    }
  }
})
);

const LoaderIcon = (props) => {
  const classes = useStyles();
  return (
    <LoopIcon  className={props.spin?classes.rotateIcon:''}  style={{width:props.width+"em"}}/>
  )
}

export default LoaderIcon
