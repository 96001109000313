import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';

class SelectBox extends ValidatorComponent {

    render() {
        const { label, errorMessages, validators, requiredError, validatorListener, instantValidate, ...rest } = this.props;
        const { isValid } = this.state;
        const errorClass = (!isValid) ? "input-error" : "";
        return (
            <div className={"form-group " + errorClass}>
                <label>{label}</label>
                <select
                    {...rest}
                    ref={(r) => { this.input = r; }}>
                    {this.props.children}
                </select>
                {this.errorText()}
            </div>
        );
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div style={{ color: 'red' }}>
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default SelectBox;