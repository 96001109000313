/* eslint-disable no-console */
import { Dialog, DialogActions, DialogContent, IconButton } from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import React, { useMemo } from "react";

const ModalDialog = ({
    isOpen,
    onClose,
    title,
    content,
    dialogActions,
    maxWidth,
    fullWidth,
    dividers,
    form
}) => {
    let dialogContent = useMemo(() => (
        <>
            <DialogContent dividers={dividers}>
                {content}
            </DialogContent>
            {dialogActions && <DialogActions>
                {dialogActions}
            </DialogActions>}
        </>
    ), [content, dialogActions, dividers]);

    dialogContent = useMemo(() => {
        if(form) {
            return (<form id={form.id} onSubmit={form.onSubmit}>
                {dialogContent}
            </form>);
        }
        return dialogContent;
    }, [form, dialogContent]);

    return (
        <Dialog
            open={isOpen}
            onClose={onClose && typeof onClose === "function" ? onClose : () => {} }
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            aria-labelledby="form-dialog-title"
            scroll="body"
        >
            <MuiDialogTitle id="form-dialog-title">
                {title}
                {onClose && typeof onClose === "function" &&
                <IconButton aria-label="close" className="btn-modal-close" onClick={onClose}>
                    <CloseIcon />   
                </IconButton>}
            </MuiDialogTitle>
            {dialogContent}
        </Dialog>
    );
};

ModalDialog.displayName = "ModalDialog";

ModalDialog.defaultProps = {
    isOpen: false,
    onClose: false,
    title: "",
    content: "",
    dialogActions: false,
    maxWidth: "sm",
    fullWidth: true,
    dividers: true,
    form: false
};

export default ModalDialog;