// @ts-nocheck
import { Divider, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import { Fragment, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { selectDairy } from "src/actions/dairy";
import { resetCustomers } from "src/actions/dairyCustomer";
import { resetDairyCustomerProducts } from "src/actions/dairyCustomerProduct";
import { resetRatelists } from "src/actions/dairyRateList";
import { resetDairyUsers } from "src/actions/dairyUsers";
import { resetDairyProducts } from "src/actions/product";
import { resetSubscription } from "src/actions/subscriptions";
import { PrimaryButton } from "../common/buttons/PrimaryButton";
import { useLanguage } from "../language/LanguageController";
import { DefaultLayout } from "../pages/common/Layout";

export default function DairySelection({ redirect = false }) {
    const history = useHistory();
    const { t } = useLanguage();
    const dispatch = useDispatch();
    const { dairies, dairy } = useSelector(state => state.dairy);
    const [selectedDairyId, setSelectedDairyId] = useState(dairy?.id);
    const onDairySelected = useCallback((dairy) => {
        dispatch(selectDairy(dairy));
        dispatch(resetSubscription());
        dispatch(resetRatelists());
        dispatch(resetCustomers());
        dispatch(resetDairyProducts());
        dispatch(resetDairyUsers());
        dispatch(resetDairyCustomerProducts());
        if(redirect) {
            history.replace("/dairy");
        }
    }, [dispatch, history, redirect]);
    return (
        <DefaultLayout title={t("text_select_dairy")} sideNav={false}>
            <div className="max-width-500 mx-auto">
                <div className={"t-card shadow-sm"}>
                    <List component="nav" aria-label="Rate List Presets List">
                        {
                            dairies.map((dairy, index) => {
                                return (
                                    <Fragment key={dairy.id}>
                                        <ListItem
                                            button
                                            selected={selectedDairyId === dairy.id}
                                            onClick={() => setSelectedDairyId(dairy.id)}
                                        >
                                            <ListItemAvatar>
                                                <span>{index + 1}</span>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={dairy.name}
                                                secondary={dairy.description}
                                            />
                                        </ListItem>
                                        {(dairies.length - 1 !== index) && <Divider />}
                                    </Fragment>
                                );
                            })
                        }
                    </List>
                    <PrimaryButton
                        variant="contained"
                        onClick={() => onDairySelected(dairies.find(dairy => dairy.id === selectedDairyId))}
                        className="btn btn-success mr-2">
                        {t("text_apply")}
                    </PrimaryButton>
                    <PrimaryButton
                        variant="contained"
                        component={Link}
                        to="/dairy/add"
                        className="btn btn-success"
                    >
                        {t("text_add_center")}
                    </PrimaryButton>
                </div>
            </div>
        </DefaultLayout>
    );
}