import React from 'react';
import AddCustomerBox from './AddCustomerBox';
import { toast, notification, loader, replaceAll, copyObject } from "../../../library/util";
import { addCustomerSuccess } from '../../../../actions/dairyCustomer';
import { connect } from 'react-redux';
import SimpleValidator from '../../../common/SimpleValidator';
import withLanguage from '../../../language/LanguageController';
import { errorHandler } from '../../../library/response';
import { checkFeatureLimit, getFeatureLimit } from '../../../common/FeatureFlag/FeatureFlag';
import { showInterstitialAd } from '../../../library/admob';
import { togglePremiumPopup } from '../../../../actions';
import { fetchRateLists } from "../../../../actions/dairyRateList";
import analytics from '../../../library/firebase/analytics';
import request from '../../../library/request';
import { DefaultLayout } from '../../common/Layout';
import { mapRateListsToSelect } from '../../../helpers/rateListHelper';

class AddCustomer extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            customers: [
                { 'key': '012.2583' }
            ],
            form_error: false,
            customer_changed: false,
            allCodes: {}
        };
        this.validator = new SimpleValidator();
    }

    componentDidMount() {
        analytics.setScreenName("AddCustomer");
        showInterstitialAd();
        if(this.props.rateLists.length <= 0) {
            this.props.fetchRateLists(this.props.dairyId);
        }
    }

    handleInput = (e) => {
        let target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        this.setState({
            [name]: value
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        try {
            if (!checkFeatureLimit("customer_limit")) {
                return false;
            }
            // validate form before submitting to server
            if (!this.validator.allValid()) {
                this.validator.showMessages();
                this.setState({
                    form_error: true
                });
                return false;
            }
            analytics.logEvent("addCustomers");
            loader.show(this.props.t("text_saving"));

            if (this.state.form_error) {
                this.setState({
                    form_error: false
                });
            }
            const { status, data, message, errors, error_code } = await request.post('/dairies/' + this.props.dairyId + '/customers', {
                withAuth: true,
                body: new FormData(document.getElementById("addCustomerForm")),
                version: "v2"
            })
            if (status === true) {
                this.props.addCustomerSuccess(data);
                this.validator.hideMessages();
                this.setState({
                    customers: [{ 'key': Math.random() }],
                    form_error: false,
                    customer_changed: false,
                    allCodes: {}
                });
                if (errors.length) {
                    let messages = message;
                    messages += `<br/><br/><div class="alert-dialog-title alert-dialog-title--material pl-0">${this.props.t("error")}</div><br/>`;
                    messages += errors.map((error) => {
                        return replaceAll(this.props.t("another_customer_with_mobile_exists"), "{mobile}", error.mobile);
                    });
                    notification("Info", messages);
                } else {
                    toast(message);
                }
            } else if (error_code != 501) {
                throw message;
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    addMoreCustomer = (e) => {
        e.preventDefault();
        const customerLimit = getFeatureLimit("customer_limit");
        const totalCustomers = this.state.customers.length + this.props.customers.length;
        if (customerLimit === "unlimited" || totalCustomers < customerLimit) {
            this.validator.purgeFields();
            let customer = { key: Math.random() };
            let customers = [...this.state.customers, customer];
            this.setState({
                customers: customers,
                customer_changed: !this.state.customer_changed
            });
        } else {
            this.props.togglePremiumPopup();
        }
    }

    removeCustomer = (e, index) => {
        e.preventDefault();
        this.validator.purgeFields();
        this.setState({
            customers: [
                ...this.state.customers.slice(0, index),
                ...this.state.customers.slice(parseInt(index) + 1)
            ],
            customer_changed: !this.state.customer_changed
        });
    }

    onCodeChange = (index, code) => {
        const allCodes = copyObject(this.state.allCodes);
        allCodes[index] = code;
        this.setState({
            allCodes: allCodes
        });
    }

    render() {
        const { t, rateLists } = this.props;
        const { form_error, customer_changed, allCodes } = this.state;
        let latestCode = this.props.latestCode || 1;
        const filteredRateLists = rateLists.filter((rateLists, index) => index > 1);
        const rateListsForSelect = mapRateListsToSelect(filteredRateLists);
        return (
            <DefaultLayout
                title={t("text_add_new_customers")}
                bottomGutter
                toolbar={false}
                back
            >
                <form
                    id="addCustomerForm"
                    ref="form"
                    onSubmit={this.handleSubmit}
                    autoComplete="off"
                    className="add_customer_form"
                    noValidate
                >
                    <div className="form-row">
                        <div className="col-12 col-md-8 col-lg-6 mx-auto">
                            <div className="mb-2">
                                {
                                    this.state.customers.map((data, index) => {
                                        return (<AddCustomerBox
                                            key={data.key}
                                            index={index}
                                            removeCustomer={this.removeCustomer}
                                            validator={this.validator}
                                            form_error={form_error}
                                            customer_changed={customer_changed}
                                            latestCode={latestCode + index}
                                            onCodeChange={this.onCodeChange}
                                            allCodes={allCodes}
                                            rateLists={rateListsForSelect}
                                            isLast={(this.state.customers.length - 1) === index}
                                        />);
                                    })
                                }
                            </div>
                            <div className="text-center mx-3 mb-5">
                                <button className="btn btn-success btn-block"
                                    onClick={this.addMoreCustomer}>
                                    + {t("text_add_more")}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="fixed-bottom p-3 px-4 bg-white shadow-top ml-lg-135">
                        <div className="form-row mx-0">
                            <div className="col-12 col-md-8 col-lg-6 mx-auto">
                                <input
                                    className="btn btn-success btn-block"
                                    type="submit"
                                    value={t("text_save")} />
                            </div>
                        </div>
                    </div>
                </form>
            </DefaultLayout>
        );
    }
}

const mapStateToProps = state => {
    return {
        dairyId: state.dairy.dairy.id,
        dairy: state.dairy.dairy,
        customers: state.dairy_customers.customers,
        latestCode: state.dairy_customers.latest_code,
        rateLists: state.dairyRateList.rateLists
    }
};

const mapDispatchToProps = {
    addCustomerSuccess,
    togglePremiumPopup,
    fetchRateLists,
}

export default connect(mapStateToProps, mapDispatchToProps)(withLanguage(AddCustomer));