// @ts-nocheck
import moment from "moment";
import ons from "onsenui";
import { FIFTEEN_DAYS, MONTHLY, TEN_DAYS, WEEKLY_MONDAY, WEEKLY_SUNDAY } from "src/_constants";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';
import { checkFeatureInSubscription } from "../common/FeatureFlag/FeatureFlag";
import { t } from "../language/LanguageController";
import User from "../models/User";
import { get10DaysCycleDiff, get15DaysCycleDiff, get1DayCycleDiff, get7DaysCycleDiffMonday, get7DaysCycleDiffSunday } from "./dateUtils";
import request from "./request";

const currencyFormatCache = {};

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    showCloseButton: true,
    allowOutsideClick: true,
    allowEscapeKey: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export const toast = (message) => {
    Toast.fire({
        icon: 'success',
        title: message
    })
};

export const toastBottom = (message) => {
    ons.notification.toast(message, {
        buttonLabel: 'Dismiss',
        animation: 'lift',
        timeout: 5000
    })
}

export const notification = (type, message) => {
    ons.notification.alert(message, {
        messageHTML: message,
        title: type ? t(type.toLowerCase()) : "",
        buttonLabels: [t("text_ok")]
    });
};

export const confirmPop = (message) => {
    return new Promise(resolve => {
        Swal.fire({
            title: t("text_confirm"),
            html: message,
            showCancelButton: true,
            confirmButtonText: t("text_yes"),
            confirmButtonColor: "#4caf50",
            cancelButtonText: t("text_no"),
            cancelButtonColor: "#aaa",
            reverseButtons: true,
            customClass: {
                container: "confirm-popup-container",
                popup: "confirm-popup",
                title: "confirm-popup-title",
                htmlContainer: "confirm-popup-html-container",
                actions: "confirm-popup-actions",
            }
        }).then((result) => {
            if (result.isConfirmed) {
                resolve(1);
            } else {
                resolve(0);
            }
        })
    });
}

export const loader = { 
    show: (text) => {
        document.getElementsByClassName("ltr-pageLoader-message")[0].innerText = text;
        document.getElementById("ltr-pageLoader").style.display = "flex";
    }, 
    hide: () => {
        document.getElementById("ltr-pageLoader").style.display = "none";
    }
};

export const socialSharing = (message, subject, feature = false) => {
    if(feature && feature !== "share_app" && !checkFeatureInSubscription("share")) {
        return false;
    }
    const options = {
        message: message, // not supported on some apps (Facebook, Instagram)
        subject: subject, // fi. for email
        files: ["", ""], // an array of filenames either locally or remotely
        url: "https://play.google.com/store/apps/details?id=in.liter.live",
        chooserTitle: "Pick an app" // Android only, you can override the default share sheet title
    };
    
    try {
        if(typeof cordova == "undefined") {
            console.log(options);
        } else {
            window.plugins.socialsharing.shareWithOptions(options, 
                result => {
                    console.log("Share result", result);
                    console.log("Share completed? " , result.completed); 
                    console.log("Shared to app: " , result.app); 
                }, 
                msg => {
                    console.log("Sharing failed with message: " + msg);
                }
            );
        }
    } catch(e) {
        console.log(e);
    }
};

export const copyObject = object => {
    if(typeof object === "object") {
        return JSON.parse(JSON.stringify(object));
    }
    return object;
};

export const formDataToJSON = formData => {
    let object = {};
    formData.forEach((value, key) => { object[key] = value; });
    return JSON.parse(JSON.stringify(object));
};

export const dateDiff = (date1, date2) => {
    return {
        years: Math.abs(Math.floor(date1.diff(date2, "years"))),
        months: Math.abs(Math.floor(date1.diff(date2, "months"))),
        days: Math.abs(Math.floor(date1.diff(date2, "days")))
    };
};

export const monthDiff = (date1, date2) => {
    let mDiff = date2.diff(date1, "month");
    return Math.abs(Math.floor(mDiff));
};

// String Functions
export const ucFirst = (string) => {
    if(!string) {
        return string;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const cleanString = str => {
    let nStr = str.replace(/(^\s*)|(\s*$)/gi,"");
    nStr = nStr.replace(/[ ]{2,}/gi," ");
    nStr = nStr.replace(/\n /,"\n");
    return nStr;
};

/**
 * Replace all the occurance of search from target string by the replacement
 * @param {string} target target of replacment
 * @param {string} search sub string which will be replace
 * @param {string} replacement string or word to be putted/replace with search
 * @returns {string} new string with replaced sub string
 */
export const replaceAll = (target, search, replacement) => {
    return target.replace(new RegExp(search, 'g'), replacement);
};

export const nl2br = (str, is_xhtml = false) => {
    let breakTag = (is_xhtml || typeof is_xhtml === "undefined") ? "<br />" : "<br>";
    return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2");
};

export const getProfilePic = user => {
    let profilePic = "./assets/img/customerimage.png";
    if(user && user.profile_pic) {
        profilePic = user.profile_pic;
    }

    return profilePic;
};

export const getToken = () => {
    const user = User.get();
    return user?.auth_token;
};

export const currencyFormat = (number, isCurrency = true) => {
    number = number === 0 ? "0" : number;
    
    if(!number) return number;
    if(isNaN(number)) {
        number = replaceAll(number, ",", "");
        number = parseFloat(number);
    }
    const CacheKey = `${number}-${isCurrency}`;
    if(currencyFormatCache[CacheKey]) {
        return currencyFormatCache[CacheKey];
    } else {
        const options = {};
        if(isCurrency) {
            options.style = "currency";
            options.currency = "INR";
        }
        const formatedCurrency = new Intl.NumberFormat("en-IN", options).format(number);
        currencyFormatCache[CacheKey] = formatedCurrency;
        return formatedCurrency;
    }
};

export const getDeviceId = () => {
    if(typeof device != "undefined") {
        return window.device.uuid;
    } else {
        let deviceId = localStorage.getItem("device_id");
        if(!deviceId) {
            deviceId = uuidv4();
            localStorage.setItem("device_id", deviceId);
        }
        return deviceId;
    }
};

export const getAppVersion = () => {
    if(typeof AppVersion !== "undefined") {
        return window.AppVersion.version
    }
    return "1.0.0";
};

export const getAppBuild = () => {
    if(typeof AppVersion !== "undefined") {
        return window.AppVersion.build
    }
    return 1;
};

export const resendOtp = async (formData) => {
    const { status, message } = await request.post('/auth/resendOtp', {
        withAuth: true,
        body: JSON.stringify(formData)
    });
    if (status === true) {
        toast(message);
    } else {
        throw message;
    }
}

export const generateRandom = length => {
    let add = 1, max = 12 - add;   // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.   

    if ( length > max ) {
        return window.generate(max) + window.generate(length - max);
    }

    max = Math.pow(10, length + add);
    let min = max/10; // Math.pow(10, n) basically
    let number = Math.floor( Math.random() * (max - min + 1) ) + min;

    return ("" + number).substring(add);
};

export const extractNumbers = str => {
    let regex = /[+-]?\d+(\.\d+)?/g;
    return str ? (str.match(regex))?.join("") : str; 
};

export const delay = async (delayTime = 0) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, delayTime);
    })
};

export const isNewerVersion = (oldVer, newVer) => {
    const oldParts = oldVer.split('.')
    const newParts = newVer.split('.')
    for (var i = 0; i < newParts.length; i++) {
      const a = parseInt(newParts[i]) || 0
      const b = parseInt(oldParts[i]) || 0
      if (a > b) return true
      if (a < b) return false
    }
    return false
};

/**
 * Sanitize any mobile number and return 10 digit mobile number
 * remove +, space, country code
 * @param {string | Number} mobileNumber 
 * @returns {string} sanitized mobile number
 */
export const sanitizeMobileNumber = (mobileNumber) => {
    if(typeof mobileNumber !== "string") {
        mobileNumber = mobileNumber.toString();
    }
    mobileNumber = replaceAll(mobileNumber, "\\+", "")
    mobileNumber = replaceAll(mobileNumber, " ", "")
    mobileNumber = replaceAll(mobileNumber, "-", "")
    if(mobileNumber.length > 10) {
        const extraNumber = mobileNumber.length - 10;
        mobileNumber = mobileNumber.substr(extraNumber);
    }

    return mobileNumber;
}

export const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {
        type:mimeString
    });
}

export const customerFilter = (filter) => {
    return (customer) => {
        filter = filter.toLowerCase();
        const name = (customer.customer_name || customer.name || "").toLowerCase();
        const code = (customer.code || "").toLowerCase();
        const telephone = (customer.c_mobile || "").toLowerCase();
        return name.includes(filter)
            || telephone.includes(filter)
            || code.includes(filter);
    }
}

export const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

export const getShiftType = () => {
    return moment().format("HH") >= 15 ? "evening" : "morning";
};

export const getNameCharacterForAvtar = (name = "") => {
    const [firstname, lastname] = name.split(" ");
    return (firstname?.charAt(0) ?? "") + (lastname?.charAt(0) ?? "").toUpperCase();
}

export const calculateMDiff = (billingCycle, startDate, dDiff) => {
    if (billingCycle === MONTHLY) {
        return dDiff.months === 1;
    } else if (billingCycle === TEN_DAYS) {
        return get10DaysCycleDiff(startDate);
    } else if (billingCycle === WEEKLY_SUNDAY) {
        return get7DaysCycleDiffSunday(startDate);
    } else if (billingCycle === WEEKLY_MONDAY) {
        return get7DaysCycleDiffMonday(startDate);
    } else if (billingCycle === FIFTEEN_DAYS) {
        return get15DaysCycleDiff(startDate);
    } else {
        return get1DayCycleDiff(startDate);
    }
}
