// @ts-nocheck
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import ons from "onsenui";
import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./assets/css/App.scss";
import "./assets/css/Poppins.css";
import {
    BrowserRouter,
    MemoryRouter
} from "react-router-dom";
import App from "./components/App";
import appJson from "../package.json";
import ConfirmProvider from "./components/confirmDialog/ConfirmProvider";

ons.ready(function () {
    ons.platform.select("android");
    renderReactApp();
});

const getRouter = () => {
    const isAndroid = typeof cordova !== "undefined";
    const isDesktop = window?.literAPI?.desktop || false;
    return isAndroid || isDesktop ? MemoryRouter : BrowserRouter;
}
const renderApp = () => {
    const Router = getRouter();
    render(
        <Router basename={appJson.base_dir}>
            <Provider store={store}>
                <ConfirmProvider>
                    <App />
                </ConfirmProvider>
            </Provider>
        </Router>,
        document.getElementById("app")
    );
};

const renderReactApp = () => renderApp();