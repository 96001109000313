import React, { useState } from 'react';
import withLanguage, { useLanguage } from 'src/components/language/LanguageController';
import HomePageSection from '../HomePageSection';
import AllGraphs from './AllGraphs';
import { getMaintenanceText } from 'src/reducers';
import { useSelector } from 'react-redux';
const SliderTab = (props) => {
  const [selectedShift, setSelectedShift] = useState("data");
  const maintenanceText = useSelector(getMaintenanceText);
  const handleGrafh = (e) => {
    setSelectedShift(e)
  }
  const { language: lang } = useLanguage();
  return (
    <>
    {maintenanceText && 
      <div className="alert alert-warning text-center text-danger font-weight-bold h5" role="alert">
        {maintenanceText?.[lang]}
      </div>}
      <div className='milkdetail_tab mt-2 mb-3 border'>
        <ul className="nav nav-justified" >
          <li className="nav-item" onClick={() => handleGrafh("data")}>
            <a className={`nav-link ${selectedShift == "data" && "active bg-light-blue text-white"}`}>
              {props.t("data_view")}
            </a>
          </li>
          <li className="nav-item" onClick={() => handleGrafh("graph")}>
            <a className={`nav-link ${selectedShift == "graph" && "active bg-light-blue text-white"}`} >
          {props.t("graph_view")}
            </a>
          </li>
        </ul>
      </div>
      <div className="tab-content">
        <div className={`tab-pane ${selectedShift == "data" && "active"}`} id="customer_milk_detail_0">
          <div className="table_container">
            <HomePageSection
              t={props.t}
              dairyId={props.dairyId}
              language={props.language}
              customers={props.customers}
            />
          </div>
        </div>
        <div className={`tab-pane ${selectedShift == "graph" && "active"}`} id="customer_milk_detail_0">
          <div className="table_container">
            <AllGraphs />
          </div>
        </div>
      </div>
    </>
  )
}

export default withLanguage(SliderTab)