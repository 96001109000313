import { Divider, List, ListItem, ListItemText } from "@material-ui/core";
import React, { Fragment } from "react";
import ModalDialog from "../common/ModalDialog";

const dairyCenterSelectionModal = ({ isOpen, onClose, dairies, selectedDairyId, onDairySelected, title }) => {
    return (
        <ModalDialog
            isOpen={isOpen}
            onClose={onClose}
            title={title}
            content={
                <List component="nav" aria-label="Dairy List">
                    {dairies.map((dairy, index) => (
                        <Fragment key={dairy.id}>
                            <ListItem
                                button
                                selected={selectedDairyId === dairy.id}
                                onClick={() => onDairySelected(dairy)}
                            >
                                <ListItemText primary={dairy.name} secondary={dairy.description} />
                            </ListItem>
                            {index < dairies.length - 1 && <Divider />}
                        </Fragment>
                    ))}
                </List>
            }
            maxWidth="sm"
            fullWidth
        />
    );
};
export default dairyCenterSelectionModal;
