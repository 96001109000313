import React from "react";
import { Icon } from "react-onsenui";
import withLanguage from "../../language/LanguageController";
import { showInterstitialAd } from "../../library/admob";
import { connect } from "react-redux";
import analytics from "../../library/firebase/analytics";
import { DefaultLayout } from '../common/Layout';
import Logo from "src/assets/img/Logo1/logo-color.png";

class ContactUs extends React.Component {

    componentDidMount () {
        analytics.setScreenName("ContactUs");
        showInterstitialAd();
    }

    render() {
        const { t, whatsapp_link, support_number } = this.props;
        return (
            <DefaultLayout title={t("text_contact_us")}>
                <section className="contact-us-banner mt-5">
                    <div className="">
                        <img src={Logo} alt="Liter" width="120" />
                    </div>
                    <div className="version mt-3">
                        {t("text_version")}: {typeof window.AppVersion !== "undefined" ? window.AppVersion.version : "1.0.0"}
                    </div>
                    <div className="contact text-center mt-3">
                        <h2>{t("text_contact")}</h2>
                        <div className="mt-2 mb-3">
                            <b>{t("text_time")}: </b> 10.00 AM - 6.00 PM
                        </div>
                        <div>
                            <a
                                href="mailto:support@liter.live"
                                className="mr-4"
                                onClick={() => analytics.logEvent("mailto")}
                            >
                                <Icon icon="fa-envelope" size={20}/>&nbsp;&nbsp;{t("text_email")}
                            </a>
                            <a
                                href={whatsapp_link}
                                target="_blank"
                                rel="noreferrer"
                                className=" mr-4"
                                onClick={() => analytics.logEvent("whatsApp")}
                            >
                                <Icon icon="fa-whatsapp" size={20}/>&nbsp;&nbsp;{t("text_whatsapp")}
                            </a>
                            <a href={`tel:${support_number}`} onClick={() => analytics.logEvent("call")}>
                                <i className="fa fa-phone fa-rotateY-180" style={{fontSize: "20px"}}/>&nbsp;&nbsp;{t("text_call")}
                            </a>
                        </div>
                    </div>
                    <div className="copyright text-center mt-3">
                        &#169; {(new Date()).getFullYear()} LoopSys Technologies.
                    </div>
                </section>
            </DefaultLayout>
        );
    }
}

const mapStateToProps = state => {
    const { userReducer: { user = {} }, defaultReducer: { support_number, whatsapp_link, youtube_ratelist_link } } = state;
    return {
        user: user ? user : {},
        support_number,
        whatsapp_link,
        youtube_ratelist_link
    };
};

export default connect(mapStateToProps)(withLanguage(ContactUs));