import React from "react";
import moment from "moment";
import CustomerPaymentHistoryController from "./CustomerPaymentHistoryController";
import { connect } from "react-redux";
import withLanguage from "../../../language/LanguageController";
import { currencyFormat } from "../../../library/util";
import BillDatePicker from "./BillDatePicker";
import CustomersSelect from "../../../pages/common/CustomersSelect";
import { DefaultLayout } from "../../common/Layout";
import Spinner from "src/components/core/Spinner";
import SubscriptionLock from "../../../common/FeatureFlag/SubscriptionLock";

class CustomerPaymentHistory extends CustomerPaymentHistoryController {

    render() {
        const { records, selectedCustomer, isLoading, isFeatureSubscribed } = this.state;
        const { t, header, filter } = this.props;
        return (
            <DefaultLayout
                back
                bottomGutter={false}
                header={header}
                title={t("text_customer_payment_statement")}
                toolbar={false}>
                {filter && <div className="mb-2">
                    <div className="bg-light rounded shadow-sm">
                        <CustomersSelect
                            customers={this.props.customers}
                            onChange={this.customerChangeHandler}
                            value={this.state.selectedCustomer} />
                    </div>
                </div>}
                <div className="t-card">
                    {filter && <div className="title right card__title card--material__title mt-0">
                        <span>
                            <BillDatePicker
                                billingCycle={"monthly"}
                                onChange={this.onDateChange}
                                isFeatureSubscribed={isFeatureSubscribed}
                            />
                        </span>
                    </div>}
                    <div className="content card__content card--material__content">
                        {!isLoading ? <div className="table-fix-header">
                            <table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th className="pl-0">{t("text_date")}</th>
                                        <th className="min-width105">
                                            {t("text_paid_amount")}
                                        </th>
                                        <th>{t("text_remark")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {records.map(record => {
                                        return (
                                            <tr key={record.id}>
                                                <td className="pl-0 nowrap">
                                                    <div className="weight-500 size-15">
                                                        {moment(record.date).format("DD MMM, YY")}
                                                    </div>
                                                </td>
                                                <td className="text-nowrap">
                                                    <span className={"size-15 " + (record.mode === "paid" ? `text-${selectedCustomer.type === "buyer" ? "red" : "green"}` : `text-${selectedCustomer.type === "buyer" ? "green" : "red"}`)}>
                                                        {currencyFormat(record.amount)}
                                                    </span>
                                                    <div className={`${record.mode === "paid" ? `text-${selectedCustomer.type === "buyer" ? "red" : "green"}` : `text-${selectedCustomer.type === "buyer" ? "green" : "red"}`}`}>
                                                        <small>{t(`text_${record.mode}`)}</small>
                                                    </div>
                                                </td>
                                                <td className="text-nowrap">
                                                    <span className="size-15">
                                                        {record.remark}
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    {records.length <= 0
                                        ? (
                                            <tr>
                                                <td colSpan="4" className="text-center">
                                                    <div className="">{t("text_no_data_available")}</div>
                                                </td>
                                            </tr>
                                        )
                                        : null}
                                </tbody>
                            </table>
                        </div> : <Spinner/>}
                    </div>
                </div>
                {header && !isFeatureSubscribed && <SubscriptionLock/>}
            </DefaultLayout>
        );
    }
}

CustomerPaymentHistory.defaultProps = {
    header: true,
    filter: true
};

const mapStateToProps = state => {
    return {
        customers: state.dairy_customers.customers,
        dairyId: state.dairy.dairy.id,
    }
}

const CustomerPaymentHistoryComp = connect(mapStateToProps)(withLanguage(CustomerPaymentHistory));

export default CustomerPaymentHistoryComp;
