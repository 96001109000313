import React, { Component } from 'react';
class InputRadio extends Component {
    render() {
        const { ...rest } = this.props;
        return (
            <ons-radio
                class={"radio-button radio-button--material radio-button--form-check-input"}>
                <input 
                    type="radio"
                    className="radio-button__input radio-button--material__input radio-button--form-check-input__input"
                    {...rest}/>
                <span className="radio-button__checkmark radio-button--material__checkmark radio-button--form-check-input__checkmark"></span>
            </ons-radio>
        );
    }
}

export default InputRadio;