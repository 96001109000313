import React from 'react';
import moment from "moment";
import { connect } from 'react-redux';
import config from '../../../../../config';
import InnerNav from '../../InnerNav';
import withLanguage from '../../../../language/LanguageController';
import analytics from '../../../../library/firebase/analytics';
import request from '../../../../library/request';
import Filter from "./Filter";
import { currencyFormat, loader } from '../../../../library/util';
import { DefaultLayout } from 'src/components/pages/common/Layout';
import { checkFeatureInSubscription } from '../../../../common/FeatureFlag/FeatureFlag';
import { togglePremiumPopup } from '../../../../../actions';
import { errorHandler } from '../../../../library/response';
import SubscriptionLock from '../../../../common/FeatureFlag/SubscriptionLock';

class MilkDetailTotal extends React.Component {

    constructor(props) {
        super(props);
        this.getMilkDetail = this.getMilkDetail.bind(this);
        const isFeatureSubscribed = checkFeatureInSubscription("dairy_milk_summary", false);
        this.state = {
            "cow_morning": {
                "milk": "",
                "amount": "",
                "rate": 0
            },
            "cow_evening": {
                "milk": "",
                "amount": "",
                "rate": 0
            },
            "cow_total": {
                "milk": "",
                "amount": "",
                "rate": 0
            },
            "buffalo_morning": {
                "milk": "",
                "amount": "",
                "rate": 0
            },
            "buffalo_evening": {
                "milk": "",
                "amount": "",
                "rate": 0
            },
            "buffalo_total": {
                "milk": "",
                "amount": "",
                "rate": 0
            },
            "morning_total": {
                "milk": "",
                "amount": "",
                "rate": 0
            },
            "evening_total": {
                "milk": "",
                "amount": "",
                "rate": 0
            },
            "total_milk": "",
            "total_amount": "",
            isFeatureSubscribed,
            ...this.getDefaultFilter()
        };
    }

    getDefaultFilter() {
        const startDate = this.props.startDate ? this.props.startDate : moment();
        const endDate = this.props.endDate ? this.props.endDate : moment();
        return {
            dairy_customer_id: '',
            customer: {
                label: this.props.t("text_all"),
                value: ''
            },
            start_date: startDate,
            end_date: endDate,
            customer_type: this.props.customerType ? this.props.customerType : "seller"
        }
    }

    componentDidMount() {
        analytics.setScreenName("MilkDetailTotal");
        this.getMilkDetail();

        this.props.onRef && this.props.onRef(this);
    }

    async getMilkDetail () {
        try {
            if(this.state.isFeatureSubscribed) {
                loader.show(this.props.t("text_loading"));
                const { dairy_customer_id, start_date, end_date, customer_type } = this.state;
                const { dairyId } = this.props;
                let filterStr = '?start_date=' + start_date.format(config.date_format);
                    filterStr += '&end_date=' + end_date.format(config.date_format);
                    filterStr += '&dairy_customer_id=' + dairy_customer_id;
                    filterStr += '&customer_type=' + customer_type;

                const { status, data } = await request.get('/dairies/' + dairyId + '/milkTotal' + filterStr, {
                    withAuth: true,
                    version: "v2"
                })
                if(status) {
                    this.setState({...data});               
                }
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
        }
    }

    customerChangeHandler = customer => {
        if(!this.state.isFeatureSubscribed) {
            this.props.dispatch(togglePremiumPopup());
            return false;
        }
        this.setState({
            dairy_customer_id: customer.value,
            customer: customer
        }, () => {
            this.getMilkDetail();
        });
    }

    customerTypeChangeHandler = customerType => {
        if(!this.state.isFeatureSubscribed) {
            this.props.dispatch(togglePremiumPopup());
            return false;
        }
        this.setState({
            customer_type: customerType,
            dairy_customer_id: "",
            customer: {
                label: this.props.t("text_all"),
                value: ''
            }
        }, () => {
            this.getMilkDetail();
        });
    }

    dateChangeHandler = (name, newDate) => {
        if(!this.state.isFeatureSubscribed) {
            this.props.dispatch(togglePremiumPopup());
            return false;
        }
        this.setState({
            [name]: newDate
        }, () => {
            this.getMilkDetail();
        });
    }

    render() {
        const { customer_type, start_date, end_date, customer, isFeatureSubscribed } = this.state;
        const { t, customers, header, bottomGutter, customerTypeFilter, dateFilter } = this.props;
        return (
            <DefaultLayout
                header={header}
                bottomGutter={bottomGutter}
                title={t("text_total_collection")}
                toolbar={false}
                back>
                <div className="form-row">
                    <div className="col-12">
                        <Filter
                            customerType={customer_type}
                            startDate={start_date}
                            endDate={end_date}
                            customers={customers}
                            customer={customer}
                            customerChangeHandler={this.customerChangeHandler}
                            customerTypeChangeHandler={this.customerTypeChangeHandler}
                            dateChangeHandler={this.dateChangeHandler}
                            customerTypeFilter={customerTypeFilter}
                            dateFilter={dateFilter}/>
                        <div className="t-card shadow-sm mb-2">
                            <div className="card__title card--material__title mb-0 mt-0">
                                <div className="d-account-h1 mt-0 mb-1 capitalize">
                                {t("text_milk")}
                                </div>
                            </div>
                            <div className="table_container">
                                <table className="table table-striped table-borderless mb-0">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{t("text_cow")}</th>
                                            <th>{t("text_buffalo")}</th>
                                            <th>{t("text_total")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{t("text_morning")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.cow_morning.milk, false)} {t("text_l")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.buffalo_morning.milk, false)} {t("text_l")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.morning_total.milk, false)} {t("text_l")}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("text_evening")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.cow_evening.milk, false)} {t("text_l")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.buffalo_evening.milk, false)} {t("text_l")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.evening_total.milk, false)} {t("text_l")}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("text_total")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.cow_total.milk, false)} {t("text_l")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.buffalo_total.milk, false)} {t("text_l")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.total_milk, false)} {t("text_l")}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="t-card shadow-sm mb-2">
                            <div className="card__title card--material__title mb-0 mt-0">
                                <div className="d-account-h1 mt-0 mb-1 capitalize">
                                    {t("text_amount")}
                                </div>
                            </div>
                            <div className="table_container">
                                <table className="table table-striped table-borderless mb-0">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{t("text_cow")}</th>
                                            <th>{t("text_buffalo")}</th>
                                            <th>{t("text_total")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{t("text_morning")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.cow_morning.amount)}/-</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.buffalo_morning.amount)}/-</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.morning_total.amount)}/-</td>
                                        </tr>
                                        <tr>
                                            <td>{t("text_evening")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.cow_evening.amount)}/-</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.buffalo_evening.amount)}/-</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.evening_total.amount)}/-</td>
                                        </tr>
                                        <tr>
                                            <td>{t("text_total")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.cow_total.amount)}/-</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.buffalo_total.amount)}/-</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.total_amount)}/-</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="t-card shadow-sm mb-2">
                            <div className="card__title card--material__title mb-0 mt-0">
                                <div className="d-account-h1 mt-0 mb-1 capitalize">
                                    {t("text_rate")}
                                </div>
                            </div>
                            <div className="table_container">
                                <table className="table table-striped table-borderless mb-0">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{t("text_cow")}</th>
                                            <th>{t("text_buffalo")}</th>
                                            <th>{t("text_total")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{t("text_morning")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.cow_morning.rate)}/{t("text_l")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.buffalo_morning.rate)}/{t("text_l")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.morning_total.rate)}/{t("text_l")}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("text_evening")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.cow_evening.rate)}/{t("text_l")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.buffalo_evening.rate)}/{t("text_l")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.evening_total.rate)}/{t("text_l")}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("text_total")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.cow_total.rate)}/{t("text_l")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.buffalo_total.rate)}/{t("text_l")}</td>
                                            <td className="text-nowrap">{currencyFormat(this.state.total_rate)}/{t("text_l")}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {!isFeatureSubscribed && <SubscriptionLock />}
                    </div>
                </div>
                <InnerNav
                    menu="milk"
                    page="milkTotal"
                    navigator={this.props.navigator}  />
            </DefaultLayout>
        );
    }
}

MilkDetailTotal.displayName = "MilkDetailTotal";

MilkDetailTotal.defaultProps = {
  header: true,
  bottomGutter: true,
  customerTypeFilter: true,
  dateFilter: true
};

const mapStateToProps = state => {
    return {
        customers: state.dairy_customers.customers,
        dairyId: state.dairy.dairy.id,
    }
}
  
const MilkDetailTotalComp = connect(mapStateToProps)(withLanguage(MilkDetailTotal));

export default MilkDetailTotalComp;