import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import defaultReducer from "../reducers";
import productReducer from "../reducers/product";
import dairyCustomerProduct from "../reducers/dairyCustomerProduct";
import dairySettings from "../reducers/dairySettings";
import dairyCustomers from "../reducers/dairyCustomer";
import dairyReducer from "../reducers/dairy";
import userReducer from "../reducers/user";
import subscriptionReducer from "../reducers/subscription";
import referralReducer from "../reducers/referral";
import dairyRateList from "../reducers/dairyRateList";
import offerStore from "../reducers/offerStore";
import gestationStore from "../reducers/gestationStore";
import reward from "../reducers/reward";
import dairyUsers from "../reducers/dairyUsers";
import addonsReducer from "../reducers/addonsReducer";

const initialState = {};

const reducer = combineReducers({
    defaultReducer,
    userReducer,
    productReducer,
    dairyCustomerProduct,
    dairySettings,
    dairy: dairyReducer,
    dairy_customers: dairyCustomers,
    subscription: subscriptionReducer,
    referral: referralReducer,
    dairyRateList,
    offerStore,
    gestationStore,
    reward,
    dairyUsers,
    addonsReducer
});

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(
        applyMiddleware(thunk)
    )
);

export default store;