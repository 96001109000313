import {
    DAIRY_RATE_LISTS_FETCH_ERROR,
    DAIRY_RATE_LISTS_FETCH_REQUEST,
    DAIRY_RATE_LISTS_FETCH_SUCCESS,
    DAIRY_RATE_LISTS_RESET,
    DAIRY_RATE_LIST_ADD,
    DAIRY_RATE_LIST_ADDED_RESET,
    DAIRY_RATE_LIST_ADD_ERROR,
    DAIRY_RATE_LIST_ADD_SUCCESS,
    DAIRY_RATE_LIST_DELETE,
    DAIRY_RATE_LIST_DELETE_ERROR,
    DAIRY_RATE_LIST_DELETE_RESET,
    DAIRY_RATE_LIST_DELETE_SUCCESS,
    DAIRY_RATE_LIST_UPDATE,
    DAIRY_RATE_LIST_UPDATE_ERROR,
    DAIRY_RATE_LIST_UPDATE_SUCCESS
} from "src/_constants/action-types";
import { t } from "src/components/language/LanguageController";
import { loader } from "src/components/library/util";
import request from "../components/library/request";
import { errorHandler } from "../components/library/response";

export const fetchRateListRequest = () => ({
    type: DAIRY_RATE_LISTS_FETCH_REQUEST
});

export const fetchRateListError = () => ({
    type: DAIRY_RATE_LISTS_FETCH_ERROR
});

export const fetchRateListSuccess = data => ({
    type: DAIRY_RATE_LISTS_FETCH_SUCCESS,
    payload: data
})

export const dairyAddListAddedReset = () => ({
    type: DAIRY_RATE_LIST_ADDED_RESET   
});

export const dairyAddListDeleteReset = () => ({
    type: DAIRY_RATE_LIST_DELETE_RESET
});

export const updateRateListName = (rateListId, name) => ({
    type: 'UPDATE_RATE_LIST_NAME',
    payload: { rateListId, name },
});

export const updateRateLists = (sortedRateLists) => ({
    type: 'UPDATE_RATE_LISTS',
    payload: sortedRateLists,
});

export const fetchRateLists = (dairyId) => {
    return async dispatch => {
        try {
            dispatch(fetchRateListRequest());
            const { data } = await request.get(`/dairies/${dairyId}/rateList`, {
                withAuth: true
            });
            dispatch(fetchRateListSuccess(data));
        } catch (error) {
            errorHandler(error);
            dispatch(fetchRateListError());
        }
    }
}

export const addRateListBegin = () => ({
    type: DAIRY_RATE_LIST_ADD,
});

export const addRateListSuccess = data => ({
    type: DAIRY_RATE_LIST_ADD_SUCCESS,
    payload: data
});

export const addRateListError = () => ({
    type: DAIRY_RATE_LIST_ADD_ERROR
});

export const addRateList = (dairyId, postData) => {
    return async dispatch => {
        try {
            dispatch(addRateListBegin());
            loader.show(t("text_saving"));
            const { status, data, message, error_code } = await request.post(`/dairies/${dairyId}/rateList`, {
                withAuth: true,
                body: JSON.stringify(postData)
            });
            if(status) {
                dispatch(addRateListSuccess(data));
            } else if(error_code != 501){
                throw message;
            }
        } catch (error) {
            errorHandler(error);
            dispatch(addRateListError());
        } finally {
            loader.hide();
        }
    }
}

export const updateRateListBegin = () => ({
    type: DAIRY_RATE_LIST_UPDATE,
});

export const updateRateListSuccess = (rateListId, data) => ({
    type: DAIRY_RATE_LIST_UPDATE_SUCCESS,
    payload: {
        rateListId,
        data
    }
});

export const updateRateListError = () => ({
    type: DAIRY_RATE_LIST_UPDATE_ERROR
});

export const updateRateList = async (
    dispatch,
    dairyId,
    rateListId,
    postData
) => {
    try {
        dispatch(updateRateListBegin());
        loader.show(t("text_updating"));
        const { status, data, message, error_code } = await request.post(`/dairies/${dairyId}/rateList/update`, {
            withAuth: true,
            body: postData
        });
        if(status) {
            dispatch(updateRateListSuccess(rateListId, data));
        } else if(error_code != 501){
            throw message;
        }
    } catch (error) {
        errorHandler(error);
        dispatch(updateRateListError());
    } finally {
        loader.hide();
    }
}

export const updateRateListCustomers = async (
    dispatch,
    dairyId,
    rateListId,
    postData
) => {
    try {
        dispatch(updateRateListBegin());
        const { status, data, message, error_code } = await request.post(`/dairies/${dairyId}/rateList/customers`, {
            withAuth: true,
            body: postData
        });
        if(status) {
            dispatch(updateRateListSuccess(rateListId, data));
        } else if(error_code != 501){
            throw message;
        }
    } catch (error) {
        errorHandler(error);
        dispatch(updateRateListError());
    } finally {
        loader.hide();
    }
}

export const deleteRateListBegin = () => ({
    type: DAIRY_RATE_LIST_DELETE,
});

export const deleteRateListSuccess = rateListId => ({
    type: DAIRY_RATE_LIST_DELETE_SUCCESS,
    payload: rateListId
});

export const deleteRateListError = () => ({
    type: DAIRY_RATE_LIST_DELETE_ERROR
});

export const deleteRateList = async (dispatch, dairyId, rateListId) => {
    try {
        dispatch(deleteRateListBegin());
        loader.show(t("text_deleting"));
        const { status, message, error_code } = await request.delete(`/dairies/${dairyId}/rateList/${rateListId}`, {
            withAuth: true
        });
        if(status) {
            dispatch(deleteRateListSuccess(rateListId));
        } else if(error_code != 501){
            throw message;
        }
    } catch (error) {
        errorHandler(error);
        dispatch(deleteRateListError());
    } finally {
        loader.hide();
    }
}

export const resetRatelists = () => ({
    type: DAIRY_RATE_LISTS_RESET
});