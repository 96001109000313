// @ts-nocheck
import React, { useCallback, useEffect } from "react";
import {
    Switch,
    Route,
    useLocation,
    Redirect,
    useHistory
} from "react-router-dom";
import CustomerMilkTotal from "src/components/pages/dairy/milk/CustomerMilkTotal";
import DairyMilkDetail from "src/components/pages/dairy/milk/DairyMilkDetail";
import MilkCollection from "src/components/pages/dairy/milk/MilkCollection";
import MilkDetailTotal from "src/components/pages/dairy/milk/MilkDetailTotal";
import MilkDiaryMilkDetailTotal from "src/components/pages/diary/MilkDetailTotal";
import AddCustomer from "../../pages/dairy/customers/AddCustomer";
import CustomersList from "../../pages/dairy/customers/CustomersList";
import EditCustomer from "../../pages/dairy/customers/EditCustomer";
import Dairy from "../../pages/dairy/Dairy";
import LoginWithPasswordPage from "../../pages/signin/LoginWithPassword";
import ProductSales from "../../pages/dairy/customer/ProductSales";
import ProductSalesForm from "../../pages/dairy/customer/ProductForm";
import ManageProduct from "src/components/pages/dairy/product/ManageProduct/ManageProduct";
import Profile from '../../pages/user/Profile';
import AppSubscription from "../../pages/subscription/AppSubscription"
import ManageBill from "src/components/pages/dairy/account/ManageBill";
import ManageDairyCustomerBill from "src/components/pages/dairy/account/ManageDairyCustomerBill/ManageDairyCustomerBill";
import AdvancePaymentManagement from "src/components/pages/dairy/account/advancePayment/AdvancePaymentManagement";
import MilkPaymentDetail from "src/components/pages/dairy/milk/MilkPaymentDetail";
import LoanManagement from "src/components/pages/dairy/loan/LoanManagement";
import DairyReport from "src/components/pages/dairy/reports/DairyReport";
import RateListNew from "src/components/pages/dairy/RateList_new/RateList";
import Setting from "src/components/pages/dairy/Setting";
import ChangePassword from "../../pages/user/ChangePassword";
import AddressForm from "../../pages/user/AddressForm";
import Plans from "../../pages/subscription/Plans";
import BillingCycle from "../../pages/subscription/BillingCycle";
import Checkout from "../../pages/subscription/Checkout";
import ContactUs from "../../pages/Contact/ContactUs";
import { useSelector } from "react-redux";
import AppSlider from "../../pages/AppSlider";
import MobileNumber from "../../pages/signin/MobileNumber";
import Registration from "../../pages/signup/Registration";
import LandingPage from "../../pages/LandingPage";
import AccountActivation from "src/components/pages/signup/AccountActivation";
import VerifyOtp from "src/components/pages/signin/VerifyOtp";
import KisanDairyPage from "src/components/pages/dairy/customer/KisanDairyPage";
import KisanPaymentLoanPage from "src/components/pages/dairy/customer/KisanPaymentLoanPage";
import MyMilkDiary from "src/components/pages/diary/MyMilkDiary";
import CustomerMilkDetail from "src/components/pages/dairy/milk/CustomerMilkDetail";
import SanctionLoan from "src/components/pages/dairy/loan/SanctionLoan";
import CustomerLoanHistory from "src/components/pages/dairy/loan/CustomerLoanHistory";
import KisanCustomerLoanHistory from "src/components/pages/dairy/loan/customer/CustomerLoanHistory";
import KisanCustomerPaymentHistory from "src/components/pages/dairy/account/customer/CustomerPaymentHistory";
import { useBrowser, useMobile, useTablet } from "src/components/library/detect-device";
import AddMilk from "src/components/pages/dairy/milk/AddMilk";
import MilkDiaryAddMilk from "src/components/pages/diary/AddMilk";
import ProductForm from "../../pages/dairy/product/ProductForm";
import DairyAccount from "src/components/pages/dairy/account/DairyAccount";
import DairySellersBill from "src/components/pages/dairy/account/DairySellersBill";
import DairyBuyersBill from "src/components/pages/dairy/account/DairyBuyersBill";
import CustomerAccount from "src/components/pages/dairy/account/CustomerAccount";
import KisanCustomerAccount from "src/components/pages/dairy/account/customer/CustomerAccount";
import AdvancePayment from "src/components/pages/dairy/account/advancePayment/AdvancePayment";
import AdvancePaymentForm from "src/components/pages/dairy/account/advancePayment/AdvancePaymentForm";
import DairyLoanDashboard from "src/components/pages/dairy/loan/DairyLoanDashboard";
import Diary from "src/components/pages/diary/Diary";
import MilkDiaryMilkDetail from "src/components/pages/diary/MilkDetail";
import KisanCustomerMilkTotal from "src/components/pages/dairy/customer/KisanCustomerMilkTotal";
import CustomerPaymentHistoryPage from "src/components/pages/dairy/account/CustomerPaymentHistory";
import MilkTotalCollection from "src/components/pages/dairy/milk/MilkTotalCollection";
import ForgotPasswordPage from "src/components/pages/signin/ForgotPassword";
import LoginSetPassword from "src/components/pages/signin/LoginSetPassword";
import CheckoutDesktop from "../../pages/subscription/CheckoutDesktop/CheckoutDesktop";
import GestationManagement from "../../pages/gestation/GestationManagement";
import Gestation from "../../pages/gestation/Gestation";
import GestationForm from "../../pages/gestation/GestationForm";
import ProductDetail from "src/components/pages/dairy/product/ProductDetail";
import KisanCustomerMilkDetail from "../../pages/dairy/customer/KisanCustomerMilkDetail";
import { DairyRoutesLayout, KisanDairyRoutesLayout } from "../../pages/common/Layout";
import Help from "../../pages/help";
import AddDairy from "../../pages/dairy/AddDairy";
import Orders from "../../pages/subscription/Orders";
import Eventer from "src/components/library/Eventer";
import Reward from "../../pages/reward/Reward";
import RewardHistoryComponent from "../../pages/reward/RewardHistoryComponent";
import useAdminLogin from "../../../hooks/useAdminLogin";
import InitialLoader from "../InitialLoader";
import DairyUsers from "../../pages/dairy/dairyUsers";
import AddUserPage from "../../pages/dairy/dairyUsers/addUserPage";
import EditUserPage from "../../pages/dairy/dairyUsers/editUserPage";
import { HasPermission } from "../AccessPermission";
import {
    ABILITY_DAIRY_CUSTOMERS,
    ABILITY_MILK_COLLECTION,
    ABILITY_PRODUCT_MANAGEMENT,
    ABILITY_PRODUCT_SALE,
    ABILITY_DAIRY_BILL,
    ABILITY_ADVANCE_PAYMENT,
    ABILITY_LOAN,
    ABILITY_DAIRY_RATE_LIST,
    ABILITY_DAIRY_SETTING,
    ABILITY_DAIRY_USERS,
    PERMISSION_VIEW,
    PERMISSION_ADD,
    PERMISSION_UPDATE,
    PERMISSION_DETAIL,
    PERMISSION_SALE_VIEW,
    PERMISSION_SALE_ADD,
    PERMISSION_VIEW_DAIRY_BILL,
    PERMISSION_VIEW_CUSTOMER_BILL
} from "../../../_constants/permissions";
import { checkFeatureInSubscription } from "../FeatureFlag/FeatureFlag";
import DairySelection from "src/components/DairySelection";

const unProtectedPaths = [
    "/login",
    "/registration",
    "/loginPassword",
    "/verifyOtp",
    "/verifyPassword",
    "/forgotPassword",
    "/setPassword"
];

const Routes = () => {
    const isMobile = useMobile();
    const isTablet = useTablet();
    const isBrowser = useBrowser();
    const location = useLocation();
    const history = useHistory();
    const [isLoading] = useAdminLogin();

    const handleFcmRoute = useCallback(({ detail }) => {
        history.push(detail.path)
    }, [history])

    useEffect(() => {
        Eventer.on("fcmRoute", handleFcmRoute);
        return () => {
            Eventer.off("fcmRoute", handleFcmRoute);
        }
    }, [handleFcmRoute])

    const showIntroSlider = parseInt(window.localStorage.getItem('show_intro_slider'));
    const { user = {}, is_login: isLogin } = useSelector(state => state.userReducer);
    const getInitialComponent = useCallback(() => {
        let CompoToReturn = AppSlider;
        if (showIntroSlider === 1 && isLogin) {
            if(user.customer_group_id == 2) {
                CompoToReturn =  Dairy;
            } else {
                CompoToReturn =  LandingPage;
            }
        }
        return CompoToReturn;
    }, [showIntroSlider, isLogin, user]);

    if(isLoading) {
        <InitialLoader />
    }

    if(!isLogin && !unProtectedPaths.includes(location.pathname)) {
        if(showIntroSlider != 1 && location.pathname != "/") {
            return <Redirect to="/" />
        } else if(showIntroSlider == 1) {
            return <Redirect to="/login" /> 
        }
    } else if(isLogin && unProtectedPaths.includes(location.pathname) && location.pathname !== "/") {
        return <Redirect to="/" />
    }

    const InitialComponent = getInitialComponent();
    return (
        <Switch>
            <Route path="/login">
                <MobileNumber />
            </Route>
            <Route path="/registration">
                <Registration />
            </Route>
            <Route path="/setPassword">
                <LoginSetPassword />
            </Route>
            <Route path="/verifyPassword">
                <LoginWithPasswordPage />
            </Route>
            <Route path="/verifyOtp">
                <VerifyOtp />
            </Route>
            <Route path="/forgotPassword">
                <ForgotPasswordPage />
            </Route>

            <Route exact path="/dairy">
                <DairyRoutesLayout>
                    <Dairy />
                </DairyRoutesLayout>
            </Route>
            <Route exact path="/dairy/add">
                <AddDairy />
            </Route>
            <Route exact path="/dairy/selection">
                <DairyRoutesLayout>
                    <DairySelection redirect />
                </DairyRoutesLayout>
            </Route>
            
            {/* Customers Routes Start */}
                <Route exact path="/customers">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_DAIRY_CUSTOMERS} access={PERMISSION_VIEW}>
                            <CustomersList />
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/customers/add">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_DAIRY_CUSTOMERS} access={PERMISSION_ADD}>
                            <AddCustomer />
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/customers/edit/:dairy_customer_id">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_DAIRY_CUSTOMERS} access={PERMISSION_UPDATE}>
                            <EditCustomer />
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/customers/milk/summary/:dairy_customer_id">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_MILK_COLLECTION} access={PERMISSION_DETAIL}>
                            <CustomerMilkDetail />
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
            {/* Customers Routes End */}

            {/* Milk Collection Routes Start */}
                <Route exact path="/milk/collection">
                    <DairyRoutesLayout>
                    {isMobile ? 
                        <HasPermission ability={ABILITY_MILK_COLLECTION} access={PERMISSION_ADD}>
                            <AddMilk />
                        </HasPermission> :
                        <MilkCollection />
                    }
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/milk/totalCollection">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_MILK_COLLECTION} access={PERMISSION_DETAIL}>
                            {isBrowser ? <MilkTotalCollection /> : <MilkDetailTotal />}
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/milk/summary">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_MILK_COLLECTION} access={PERMISSION_DETAIL}>
                            <DairyMilkDetail />
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/milk/customer/:dairyCustomerId?">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_MILK_COLLECTION} access={PERMISSION_DETAIL}>
                            {(isBrowser || isTablet) ? <CustomersList /> : <CustomerMilkTotal />}
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
            {/* Milk Collection Routes End */}

            {/* Dairy product management routes start */}
                <Route exact path="/dairy/product/addSales">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_PRODUCT_SALE} access={PERMISSION_SALE_ADD}>
                            <ProductSalesForm />
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/dairy/product/salesSummary">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_PRODUCT_SALE} access={PERMISSION_SALE_VIEW}>
                            <ProductSales />
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/dairy/product/add">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_PRODUCT_MANAGEMENT} access={PERMISSION_ADD}>
                            <ProductForm />
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/dairy/product/edit/:product_id?">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_PRODUCT_MANAGEMENT} access={PERMISSION_UPDATE}>
                            <ProductForm />
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/dairy/product">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_PRODUCT_MANAGEMENT} access={PERMISSION_VIEW}>
                            <ManageProduct />
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/dairy/product/detail/:product_id?">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_PRODUCT_SALE} access={PERMISSION_SALE_VIEW}>
                            <ProductDetail />
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
            {/* Dairy product management routes end */}

            {/* Dairy billing routes start */}
                <Route exact path="/dairy/bill">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_DAIRY_BILL} access={PERMISSION_VIEW_DAIRY_BILL}>
                            {isBrowser ? <ManageBill /> : <DairyAccount />}
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/dairy/bill/sellersBill">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_DAIRY_BILL} access={PERMISSION_VIEW_CUSTOMER_BILL}>
                            <DairySellersBill />
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/dairy/bill/buyersBill">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_DAIRY_BILL} access={PERMISSION_VIEW_CUSTOMER_BILL}>
                            <DairyBuyersBill />
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/dairy/bill/customer/:dairyCustomerId?">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_DAIRY_BILL} access={PERMISSION_VIEW_CUSTOMER_BILL}>
                            {isBrowser ? <ManageDairyCustomerBill /> : <CustomerAccount />}
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/dairy/paymentSummary">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_DAIRY_BILL} access={PERMISSION_VIEW_CUSTOMER_BILL}>
                            <MilkPaymentDetail />
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/dairy/advancePayment">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_ADVANCE_PAYMENT} access={PERMISSION_VIEW}>
                            {isMobile ? <AdvancePayment /> : <AdvancePaymentManagement />}
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/dairy/advancePayment/add">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_ADVANCE_PAYMENT} access={PERMISSION_ADD}>
                            <AdvancePaymentForm />
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/dairy/account/paymentHistory">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_DAIRY_BILL} access={PERMISSION_VIEW_CUSTOMER_BILL}>
                            <CustomerPaymentHistoryPage />
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
            {/* Dairy billing routes end */}

            {/* Dairy loan routes start */}
                <Route exact path="/dairy/loan">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_LOAN} access={PERMISSION_VIEW}>
                            {isBrowser ? <LoanManagement /> : <DairyLoanDashboard />}
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/dairy/loan/sanction">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_LOAN} access={PERMISSION_ADD}>
                            <SanctionLoan />
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/dairy/loan/customer/:dairy_customer_id?">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_LOAN} access={PERMISSION_VIEW}>
                            <CustomerLoanHistory />
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
            {/* Dairy loan routes end */}

            {/* Dairy report routes start */}
                <Route exact path="/dairy/report">
                    <DairyRoutesLayout>
                        <DairyReport />
                    </DairyRoutesLayout>
                </Route>
            {/* Dairy report routes end */}

            {/* Dairy setting/rate chart routes start */}
                <Route exact path="/dairy/setting/rateList">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_DAIRY_RATE_LIST} access={PERMISSION_VIEW}>
                            <RateListNew />
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>

                <Route exact path="/dairy/setting">
                    <DairyRoutesLayout>
                        <HasPermission ability={ABILITY_DAIRY_SETTING} access={PERMISSION_VIEW}>
                            <Setting />
                        </HasPermission>
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/dairy/setting/users">
                    <DairyRoutesLayout>
                        {checkFeatureInSubscription(ABILITY_DAIRY_USERS, false) && <HasPermission ability={ABILITY_DAIRY_USERS} access={PERMISSION_VIEW}>
                            <DairyUsers />
                        </HasPermission>}
                    </DairyRoutesLayout>
                </Route>
                <Route exact path="/dairy/setting/users/add">
                    <DairyRoutesLayout>
                        {checkFeatureInSubscription(ABILITY_DAIRY_USERS, false) && <HasPermission ability={ABILITY_DAIRY_USERS} access={PERMISSION_ADD}>
                            <AddUserPage />
                        </HasPermission>}
                    </DairyRoutesLayout>
                </Route>                
                <Route exact path="/dairy/setting/users/:dairyUserId">
                    <DairyRoutesLayout>
                        {checkFeatureInSubscription(ABILITY_DAIRY_USERS, false) && <HasPermission ability={ABILITY_DAIRY_USERS} access={PERMISSION_UPDATE}>
                            <EditUserPage />
                        </HasPermission>}
                    </DairyRoutesLayout>
                </Route>
            {/* Dairy setting/rate chart routes end */}

            {/* Kisan dairy routes start */}
                <Route exact path="/dairy/:dairy_id">
                    <KisanDairyRoutesLayout>
                        {isBrowser ? <KisanDairyPage /> : <KisanCustomerMilkTotal />}
                    </KisanDairyRoutesLayout>
                </Route>
                <Route exact path="/dairy/:dairy_id/milkDetail/:dairyCustomerId">
                    <KisanDairyRoutesLayout>
                        <KisanCustomerMilkDetail />
                    </KisanDairyRoutesLayout>
                </Route>
                <Route exact path="/dairy/:dairy_id/bill">
                    <KisanDairyRoutesLayout>
                        <KisanCustomerAccount />
                    </KisanDairyRoutesLayout>
                </Route>
                <Route exact path="/dairy/:dairy_id/loan">
                    <KisanDairyRoutesLayout>
                        <KisanCustomerLoanHistory />
                    </KisanDairyRoutesLayout>
                </Route>
                <Route exact path="/dairy/:dairy_id/payment">
                    <KisanDairyRoutesLayout>
                        {isBrowser ? <KisanPaymentLoanPage /> : <KisanCustomerPaymentHistory />}
                    </KisanDairyRoutesLayout>
                </Route>
            {/* Kisan dairy routes end */}


            {/* Kisan milk diary routes start */}
                <Route exact path="/diary">
                    {isBrowser ? <MyMilkDiary /> : <Diary />}
                </Route>
                <Route exact path="/diary/addMilk">
                    <MilkDiaryAddMilk />
                </Route>
                <Route exact path="/diary/milkDetailTotal">
                    <MilkDiaryMilkDetailTotal />
                </Route>
                <Route exact path="/diary/milkDetail">
                    <MilkDiaryMilkDetail />
                </Route>
            {/* Kisan milk diary routes end */}


            {/* Account routes start */}
                <Route exact path="/accountActivation">
                    <AccountActivation />
                </Route>

                <Route exact path="/account/profile">
                    <Profile />
                </Route>

                <Route exact path="/account/address/edit">
                    <AddressForm />
                </Route>

                <Route exact path="/account/changePassword">
                    <ChangePassword/>
                </Route>
                <Route exact path="/account/setPassword">
                    <LoginSetPassword />
                </Route>
            {/* Account routes ends */}

            {/* Gestation Routes Start */}
                <Route exact path="/gestation">
                    {isBrowser ? <GestationManagement /> : <Gestation/>}
                </Route>
                <Route exact path="/gestation/add">
                    <GestationForm />
                </Route>
            {/* Gestation Routes End */}

            {/* Subscription routes start */}
            <Route exact path="/subscription">
                <DairyRoutesLayout>
                    <AppSubscription />
                </DairyRoutesLayout>
            </Route>
            <Route exact path="/subscription/orders">
                <DairyRoutesLayout>
                    <Orders />
                </DairyRoutesLayout>
            </Route>
            <Route exact path="/subscription/plans">
                <DairyRoutesLayout>
                    <Plans />
                </DairyRoutesLayout>
            </Route>
            <Route exact path="/subscription/billingCycle">
                <DairyRoutesLayout>
                    <BillingCycle />
                </DairyRoutesLayout>
            </Route>
            <Route exact path="/subscription/checkout">
                <DairyRoutesLayout>
                    {isBrowser ? <CheckoutDesktop /> : <Checkout />}
                </DairyRoutesLayout>
            </Route>
            {/* Subscription routes end */}

            <Route exact path="/help">
                <Help />
            </Route>
            
            <Route exact path="/contact">
                <ContactUs />
            </Route>

            <Route exact path="/rewards">
                <DairyRoutesLayout>
                    <Reward />
                </DairyRoutesLayout>
            </Route>           

            <Route exact path="/reward-history">
                <DairyRoutesLayout>
                    <RewardHistoryComponent />
                </DairyRoutesLayout>
            </Route>

            <Route exact path="/">
                {user?.customer_group_id == 2  ?
                    <DairyRoutesLayout>
                        <InitialComponent />
                    </DairyRoutesLayout> :
                    <InitialComponent />
                }
            </Route>          
            
        </Switch>
    );
};

export default Routes;