// @ts-nocheck
import { AppBar, Tabs, Menu, MenuItem } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addRateList, dairyAddListAddedReset, dairyAddListDeleteReset, updateRateListName, updateRateLists} from "src/actions/dairyRateList";
import { ABILITY_DAIRY_RATE_LIST, PERMISSION_ADD } from "../../../../../_constants/permissions";
import { togglePremiumPopup } from "../../../../../actions";
import { HasPermission } from "../../../../common/AccessPermission";
import { getFeatureLimit } from "../../../../common/FeatureFlag/FeatureFlag";
import { useLanguage } from "../../../../language/LanguageController";
import RateListTab from "./RateListTab";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';

const RateListTabs = ({ onTabChange, handleRenameSubmit }) => {
    const { t } = useLanguage();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const [moreAnchorEl, setMoreAnchorEl] = useState(null);
    const [visibleTabs, setVisibleTabs] = useState([]);
    const [hiddenTabs, setHiddenTabs] = useState([]);
    const [maxVisible, setMaxVisible] = useState(4);

    const {
        dairyRateList: { rateLists, isListAdded, isListDeleted },
        dairy: { dairy },
    } = useSelector((state) => state);

    const sortRateLists = (lists) => {
        return [...lists].sort((a, b) => {
            const getNumber = (name) => {
                const match = name.match(/Rate List (\d+)/);
                return match ? parseInt(match[1], 10) : 0;
            };

            const numA = getNumber(a.name);
            const numB = getNumber(b.name);

            return numA - numB || a.rate_list_id - b.rate_list_id;
        });
    };

    const addNewRateList = () => {
        const feature = "no_of_rate_lists";
        const rateListsLimit = getFeatureLimit(feature);
    
        if (rateListsLimit === "unlimited") {
            const existingNumbers = rateLists
                .map((list) => {
                    const match = list.name.match(/Rate List (\d+)/);
                    return match ? parseInt(match[1], 10) : 0;
                })
                .filter((num) => !isNaN(num));
    
            const nextNumber = existingNumbers.length > 0 ? Math.max(...existingNumbers) + 1 : 1;
            const newRateListName = `Rate List ${nextNumber}`;
    
            dispatch(
                addRateList(dairy.id, {
                    name: newRateListName,
                    rate_list_type_id: 7,
                })
            );
        } else {
            dispatch(togglePremiumPopup({ feature }));
        }
    };

    const handleRename = (renamedRateListId, newName) => {
        const renamedRateListIndex = rateLists.findIndex(
            (rateList) => rateList.rate_list_id === renamedRateListId
        );
    
        if (renamedRateListIndex === -1) return;
    
        const renamedRateList = rateLists[renamedRateListIndex];
        const updatedRateList = { ...renamedRateList, name: newName };
    
        const updatedRateLists = [...rateLists];
        updatedRateLists.splice(renamedRateListIndex, 1, updatedRateList);
    
        const sortedRateLists = sortRateLists(updatedRateLists);
        const visibleIndex = visibleTabs.findIndex(tab => tab.rate_list_id === renamedRateListId);
        if (visibleIndex !== -1) {
            const newVisibleTabs = [...visibleTabs];
            newVisibleTabs.splice(visibleIndex, 1, updatedRateList);
            setVisibleTabs(newVisibleTabs);
            setHiddenTabs(hiddenTabs); 
        }
    
        setActiveTab(visibleIndex !== -1 ? visibleIndex : visibleTabs.length - 1);
    
        const renamedTabGlobalIndex = rateLists.findIndex(
            (rateList) => rateList.rate_list_id === renamedRateListId
        );
        dispatch(updateRateLists(sortedRateLists));
        dispatch(updateRateListName(renamedRateListId, newName));
        onTabChange(renamedTabGlobalIndex);
    };
    

    const handleTabChange = useCallback(
        (index) => {
            const selectedTab = visibleTabs[index];
            const globalIndex = rateLists.findIndex(
                (rateList) => rateList.rate_list_id === selectedTab?.rate_list_id
            );
    
            setActiveTab(index);
            onTabChange(globalIndex);
        },
        [visibleTabs, onTabChange]
    );

    useEffect(() => {
        const updateTabs = () => {
            const sortedRateLists = sortRateLists(rateLists);
            if (sortedRateLists.length <= maxVisible) {
                setVisibleTabs(sortedRateLists);
                setHiddenTabs([]);
            } else {
                setVisibleTabs(sortedRateLists.slice(0, maxVisible))
                setHiddenTabs(sortedRateLists.slice(maxVisible, sortedRateLists.length));
            setActiveTab(0); 
            onTabChange(0);
            }
        };
        updateTabs();
    }, [maxVisible]);

    useEffect(() => {
        const sortedRateLists = sortRateLists(rateLists);
        if (isListAdded || isListDeleted) {
            if (isListAdded) {
                if (visibleTabs.length <= 2 && maxVisible > 2) {
                    setVisibleTabs(sortedRateLists.slice(0, maxVisible-1));
                }
                else {
                    setVisibleTabs(sortedRateLists.slice(0, maxVisible-1).concat(sortedRateLists[sortedRateLists.length - 1]));
                    setHiddenTabs(sortedRateLists.slice(maxVisible-1, sortedRateLists.length-1));
                }
                if (rateLists.length > maxVisible) {
                    setActiveTab(visibleTabs.length - 1);
                }
                else {
                    setActiveTab(rateLists.length - 1);
                }
                onTabChange(rateLists.length - 1);
                if (maxVisible === 1) {
                    const sortedRateLists = sortRateLists(rateLists);
                    setVisibleTabs(sortedRateLists.slice(0, maxVisible - 1).concat(sortedRateLists[sortedRateLists.length - 1]));
                    setHiddenTabs(sortedRateLists.slice(maxVisible - 1, sortedRateLists.length-1));
                }
            } else if (isListDeleted) {
                setVisibleTabs(sortedRateLists.slice(0, maxVisible))
                setHiddenTabs(sortedRateLists.slice(maxVisible, sortedRateLists.length));
                setActiveTab(0); 
                onTabChange(0);
            }
            dispatch(dairyAddListAddedReset());
            dispatch(dairyAddListDeleteReset());
        }
    }, [isListAdded, isListDeleted]);

    useEffect(() => {
        const updateMaxVisible = () => {
            const width = window.innerWidth;
            if (width < 600) setMaxVisible(1);
            else if (width < 1025) setMaxVisible(2);
            else setMaxVisible(4);
        };

        updateMaxVisible();
        window.addEventListener("resize", updateMaxVisible);

        return () => {
            window.removeEventListener("resize", updateMaxVisible);
        };
    }, []);

    const handleMoreClick = (event) => {
        setMoreAnchorEl(event.currentTarget);
    };

    const handleMoreClose = () => {
        setMoreAnchorEl(null);
    };

    const handleMoreItemClick = (rateList, index) => {
        const newVisibleTabs = [...visibleTabs];
        const newHiddenTabs = [...hiddenTabs];
        const lastVisibleTab = newVisibleTabs.pop();
        newVisibleTabs.push(rateList);
        newHiddenTabs.splice(index, 1, lastVisibleTab);

        setVisibleTabs(newVisibleTabs);
        const sortedHiddenLists = sortRateLists(newHiddenTabs);
        setHiddenTabs(sortedHiddenLists);

        const rateListIndex = rateLists.findIndex(
            (r) => r.rate_list_id === rateList.rate_list_id
        );
        setActiveTab(visibleTabs.length - 1);
        onTabChange(rateListIndex);
        handleMoreClose();
    };

    return (
        <AppBar position="static" color="default" className="flex-row rate-list-tabs">
            <Tabs
                value={activeTab} 
                onChange={(event, newValue) => {
                    setActiveTab(newValue);
                    onTabChange(newValue);
                }}
                indicatorColor="primary"
                textColor="primary"
                className="tabs-container"
            >
                {visibleTabs.map((rateList, index) => (
                    <RateListTab
                        key={rateList.rate_list_id}
                        index={index}
                        {...rateList}
                        onRename={handleRename}
                        onClick={() => handleTabChange(index)}
                    />
                ))}
            </Tabs>

            {hiddenTabs.length > 0 && (
                <button
                    aria-controls="more-menu"
                    aria-haspopup="true"
                    onClick={handleMoreClick}
                    className="btn rate_tab-btn-more-add"
                >
                    <MoreVertIcon />
                    {t("text_more")}
                </button>
            )}

            <Menu
                id="more-menu"
                anchorEl={moreAnchorEl}
                keepMounted
                open={Boolean(moreAnchorEl)}
                onClose={handleMoreClose}
            >
                {hiddenTabs.map((rateList, index) => {
                    let rateListName = rateList.name;
                    try {
                        const parsedName = JSON.parse(rateList.name);
                        rateListName = parsedName[t("language_key")] || Object.values(parsedName)[0];
                    } catch (e) {
                    }

                    return (
                        <MenuItem
                            key={rateList.rate_list_id}
                            onClick={() => handleMoreItemClick(rateList, index)}
                        >
                            {rateListName}
                        </MenuItem>
                    );
                })}
            </Menu>
            <HasPermission ability={ABILITY_DAIRY_RATE_LIST} access={PERMISSION_ADD}>
                <button className="btn rate_tab-btn-more-add" onClick={addNewRateList}>
                    <AddIcon />
                    {t("text_add")}
                </button>
            </HasPermission>
        </AppBar>
    );
};

export default RateListTabs;
